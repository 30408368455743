import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as CollectorMapActions from './collector-map.actions';
import { CollectorMapEntity } from './collector-map.models';

export const COLLECTOR_MAP_FEATURE_KEY = 'collectorMap';

export interface CollectorMapState extends EntityState<CollectorMapEntity> {
  selectedId?: string | number; // which CollectorMap record has been selected
  loaded: boolean; // has the CollectorMap list been loaded
  error?: string | null; // last known error (if any)
}

export interface CollectorMapPartialState {
  readonly [COLLECTOR_MAP_FEATURE_KEY]: CollectorMapState;
}

export const collectorMapAdapter: EntityAdapter<CollectorMapEntity> = createEntityAdapter<CollectorMapEntity>();

export const initialCollectorMapState: CollectorMapState = collectorMapAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialCollectorMapState,
  on(CollectorMapActions.initCollectorMap, (state) => ({ ...state, loaded: false, error: null })),
  on(CollectorMapActions.loadCollectorMapSuccess, (state, { collectorMap }) =>
    collectorMapAdapter.setAll(collectorMap, { ...state, loaded: true })
  ),
  on(CollectorMapActions.loadCollectorMapFailure, (state, { error }) => ({ ...state, error }))
);

export function collectorMapReducer(state: CollectorMapState | undefined, action: Action) {
  return reducer(state, action);
}
