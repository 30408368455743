import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { User, UserResponse, UserTypeIcon } from '@dc/data-models';

@Component({
  selector: 'dc-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  public user!: User;

  public readonly userTypeIcon = UserTypeIcon;

  constructor(private usersService: UsersService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    if (!this.route.snapshot.params['id']) {
      throw new Error('User id is null');
    }

    this.usersService.get(this.route.snapshot.params['id']).subscribe((userResp: UserResponse) => {
      this.user = userResp.user;
    });
  }
}
