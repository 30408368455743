import { createAction, props } from '@ngrx/store';
import { CollectorDebtsEntity, CollectorDebtsRequestParams } from './collector-debts.models';

export const initCollectorDebts = createAction('[CollectorDebts Page] Init');

export const loadCollectorDebts = createAction(
  '[CollectorDebts Page] Load CollectorDebts',
  props<{ requestParams: CollectorDebtsRequestParams }>()
);

export const loadCollectorDebtsSuccess = createAction(
  '[CollectorDebts/API] Load CollectorDebts Success',
  props<{ collectorDebts: CollectorDebtsEntity[]; totalCount: number }>()
);

export const loadCollectorDebtsFailure = createAction(
  '[CollectorDebts/API] Load CollectorDebts Failure',
  props<{ error: any }>()
);

export const selectCollectorDebt = createAction(
  '[CollectorDebts Page] Select CollectorDebt',
  props<{ debtId: string }>()
);

export const removeAssignment = createAction('[CollectorDebts Page] Remove assignment', props<{ debtId: string }>());
export const removeAssignmentSuccess = createAction('[CollectorDebts Page] Remove assignment success');
export const removeAssignmentFailure = createAction(
  '[CollectorDebts Page] Remove assignment failure',
  props<{ error: any }>()
);

export const acceptAssignment = createAction('[CollectorDebts Page] Accept assignment', props<{ debtId: string }>());
export const acceptAssignmentSuccess = createAction('[CollectorDebts Page] Accept assignment success');
export const acceptAssignmentFailure = createAction(
  '[CollectorDebts Page] Accept assignment failure',
  props<{ error: any }>()
);
