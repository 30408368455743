import { createAction, props } from '@ngrx/store';
import { DebtorsEntity, DebtorsRequestParams } from './debtors.models';

export const initDebtors = createAction('[Debtors Page] Init');

export const loadDebtors = createAction(
  '[Debtors Page] Load Debtors',
  props<{ requestParams: DebtorsRequestParams }>()
);

export const loadDebtorsSuccess = createAction(
  '[Debtors/API] Load Debtors Success',
  props<{ debtors: DebtorsEntity[]; totalCount: number }>()
);

export const loadDebtorsFailure = createAction('[Debtors/API] Load Debtors Failure', props<{ error: any }>());
