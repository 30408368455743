import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AUTH_MODULE_CONFIG, AuthModuleConfig, RegisterInput } from '@dc/data-models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_META, MatchStringValidator } from '@dc/shared';
import { AuthFacade } from '@dc/auth';

@Component({
  selector: 'dc-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {
  public registerForm!: FormGroup;
  public appName = this.authModuleConfig.appNameKey;
  public version = APP_META.version;

  public emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  public nameCtrl = new FormControl('', [Validators.required]);
  public surnameCtrl = new FormControl('', [Validators.required]);
  public phoneCtrl = new FormControl('');

  public isRegisterEmailSent$ = this.authFacade.isRegisterEmailSent$;

  constructor(
    @Inject(AUTH_MODULE_CONFIG) private authModuleConfig: AuthModuleConfig,
    private authFacade: AuthFacade,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.authFacade.registerPageInit();
    this.registerForm = this.formBuilder.group(
      {
        name: this.nameCtrl,
        surname: this.surnameCtrl,
        phone: this.phoneCtrl,
        email: this.emailCtrl,
        emailRepeat: ['', [Validators.required, Validators.email]],
      },
      {
        validators: MatchStringValidator('email', 'emailRepeat'),
      }
    );
  }

  public ngOnDestroy(): void {
    this.registerForm.reset();
  }

  public register(): void {
    if (!this.registerForm.valid) {
      return;
    }
    const registerInput: RegisterInput = {
      email: this.emailCtrl.value,
      name: this.nameCtrl.value,
      surname: this.surnameCtrl.value,
    };

    if (this.phoneCtrl.value) {
      registerInput.phone = '+48' + this.phoneCtrl.value;
    }

    this.authFacade.register(registerInput);
  }
}
