import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Profile } from '../../+state/auth.models';

@Component({
  selector: 'dc-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoComponent implements OnChanges {
  @Input() profile!: Profile | null;

  public initials = '';

  public ngOnChanges(): void {
    if (this.profile) {
      this.initials = this.getInitials(this.profile);
    }
  }

  private getInitials(profile: Profile): string {
    let initials: string;
    switch (true) {
      case !!(profile.name?.length && profile.surname?.length):
        initials = profile.name.substring(0, 1) + profile.surname.substring(0, 1);
        break;
      case !!profile.name?.length:
        initials = profile.name.substring(0, 2);
        break;
      case !!profile.surname?.length:
        initials = profile.surname.substring(0, 2);
        break;
      default:
        initials = profile.email.substring(0, 2);
    }
    return initials;
  }
}
