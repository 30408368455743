<div class="company item-details" *ngIf="company">
  <h1 class="item-details__header">
    <button class="item-details__back-btn" mat-icon-button type="button" matTooltip="{{ 'MENU.BACK' | translate }}"
            [routerLink]="'..'">
      <mat-icon>arrow_back</mat-icon>
    </button>
    {{company.name}}
  </h1>

  <div class="item-details__cards">
    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.DETAILS.DETAILS' | translate }}</mat-card-title>
      <mat-card-content>
        <p>{{ 'COMPANIES.DETAILS.COMPANY_TYPE_FILED' | translate }}:
          <ng-container class="item-details__desc-icon" *ngFor="let type of company.companyType; let last = last">
            <mat-icon fontSet="material-icons-outlined" matTooltip="{{ 'COMPANIES.COMPANY_TYPES.' + type | translate }}">
              {{ companyTypeIcon[type] }}
            </mat-icon>
            {{ 'COMPANIES.COMPANY_TYPES.' + type | translate }}<ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>
        <p *ngIf="company.createdDate">
          <mat-icon matTooltip="{{ 'COMPANIES.DETAILS.DATE_CREATED' | translate }}" fontSet="material-icons-outlined">today</mat-icon>
          {{ company.createdDate | date }}
        </p>
        <p *ngIf="company.updatedDate">
          <mat-icon matTooltip="{{ 'COMPANIES.DETAILS.DATE_UPDATED' | translate }}" fontSet="material-icons-outlined">edit_calendar</mat-icon>
          {{ company.updatedDate | date }}
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.ADD.ADDRESS.ADDRESS' | translate }}</mat-card-title>
      <mat-card-content>
        <p *ngIf="company.address" class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.ADD.ADDRESS.ADDRESS' | translate }}" fontSet="material-icons-outlined">place</mat-icon>
          <dc-company-address [address]="company.address"></dc-company-address>
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.ADD.CONTACT' | translate }}</mat-card-title>
      <mat-card-content>
        <p *ngIf="company.phone" class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.LIST.PHONE' | translate }}" fontSet="material-icons-outlined">phone</mat-icon>
          {{company.phone}}
        </p>
        <p *ngIf="company.email" class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.LIST.EMAIL' | translate }}" fontSet="material-icons-outlined">email</mat-icon>
          {{company.email}}
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.DETAILS.TAX_DATA' | translate }}</mat-card-title>
      <mat-card-content>
        <p class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.DETAILS.TAX_DATA' | translate }}" fontSet="material-icons-outlined">price_check</mat-icon>
          {{ 'COMPANIES.LIST.TAX_ID' | translate }}: {{company.taxationId}}<br>
          {{ 'COMPANIES.DETAILS.VAT_PAYER' | translate }}: {{ (company.isTaxPayer ? 'YES' : 'NO') | translate }}<br>

          <ng-container *ngIf="company.pcaList?.length">
            {{ 'COMPANIES.ADD.PCA_FORM_FILED' | translate }}: {{company.pcaList?.join(", ") }}<br>
          </ng-container>
        </p>

        <p *ngIf="company.nrcNo" class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.ADD.EMAIL' | translate }}" fontSet="material-icons-outlined">gavel</mat-icon>
          {{ 'COMPANIES.DETAILS.NCR_NO'}}: {{ company.nrcNo }}<br>
          <ng-container *ngIf="company.nrc?.name">
            {{company.nrc?.name }}<br>
          </ng-container>
          <ng-container *ngIf="company.nrc?.id">
            {{company.nrc?.id }}<br>
          </ng-container>
          <ng-container *ngIf="company?.nrc?.address">
            <dc-company-address [address]="company.nrc?.address"></dc-company-address>
          </ng-container>
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.LIST.COMPANY_FORM' | translate }}</mat-card-title>
      <mat-card-content>
        <p class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.LIST.COMPANY_FORM' | translate }}" fontSet="material-icons-outlined">account_balance</mat-icon>

          <ng-container *ngIf="company.companyForm">
            {{ 'COMPANIES.COMPANY_FORMS.' + company.companyForm.formType | translate }}<br>
            <!-- @TODO show NCR data when backend returns correct object and not string -->
            <ng-container *ngIf="company.companyForm?.ncr">
              <ng-container *ngIf="company.companyForm?.ncr?.court">{{ company.companyForm.ncr?.court }}<br></ng-container>
              <ng-container *ngIf="company.companyForm?.ncr?.address">{{ company.companyForm.ncr?.address }}<br></ng-container>
              <ng-container *ngIf="company.companyForm?.ncr?.departments">{{ company.companyForm.ncr?.departments }}<br></ng-container>
            </ng-container>

            <ng-container *ngIf="company.companyForm?.ncrNumber">
              {{ 'COMPANIES.DETAILS.NCR_NO' | translate }}: {{ company.companyForm.ncrNumber }}<br>
            </ng-container>
            <ng-container *ngIf="company.companyForm?.capital">
              {{ 'COMPANIES.DETAILS.SHARE_CAPITAL' | translate }}: {{ company.companyForm.capital }}<br>
            </ng-container>
            <ng-container *ngIf="company.companyForm?.capital">
              {{ 'COMPANIES.DETAILS.SHARE_CAPITAL_FULLY_PAID' | translate }}: {{ (company.companyForm.fullyPaidCapital ? 'YES' : 'NO') | translate }}<br>
            </ng-container>
            <ng-container *ngIf="company.companyForm?.totalShares">{{ company.companyForm.totalShares }}</ng-container>
          </ng-container>

        <ng-container *ngIf="company.shareCapital" class="item-details__desc-icon">
          {{ 'COMPANIES.DETAILS.SHARE_CAPITAL'}}: {{ company.shareCapital.amount}} {{company.shareCapital.currency}}<br>
          {{ 'COMPANIES.DETAILS.SHARE_CAPITAL_FULLY_PAID' }}: {{ (company.shareCapital.fullyPaidUp ? 'YES' : 'NO') | translate }}
        </ng-container>
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="item-details__card">
      <mat-card-title>{{ 'COMPANIES.DETAILS.BANK_ACCOUNT' | translate }}</mat-card-title>
      <mat-card-content>
        <p class="item-details__desc-icon">
          <mat-icon matTooltip="{{ 'COMPANIES.DETAILS.BANK_ACCOUNT' | translate }}" fontSet="material-icons-outlined">price_check</mat-icon>
          {{company.bankAccount.iban}}<br>
          {{company.bankAccount.bankName}}<br>
          {{company.bankAccount.swift}}<br>
          <ng-container *ngIf="company.bankAccount.country">{{company.bankAccount.country}}</ng-container>
        </p>
      </mat-card-content>
    </mat-card>

  </div>

  <div class="company__actions">
    <input type="file" class="company__file-input" #fileInput accept=".csv" (change)="importDebts($event)"/>
    <button mat-flat-button color="primary" type="button" (click)="triggerFileSelectForImport(fileInput) ">
      <mat-icon>import_export</mat-icon>
      {{'DEBTS.IMPORT' | translate}}
    </button>
  </div>

  <nav mat-tab-nav-bar #navBar class="dcol-tabs">
    <a mat-tab-link
       [routerLink]="['import-debts-last']"
       routerLinkActive #rla1="routerLinkActive"
       [class.dcol-tab__title-active]="rla1.isActive"
       [active]="rla1.isActive">
      <mat-icon>done</mat-icon>
      <span>{{'DEBTS.IMPORT_STATUS_LAST' | translate}}</span>
    </a>
    <a mat-tab-link
       [routerLink]="['import-debts']"
       routerLinkActive #rla2="routerLinkActive"
       [class.dcol-tab__title-active]="rla2.isActive"
       [active]="rla2.isActive">
      <mat-icon>checklist</mat-icon>
      <span>{{'DEBTS.SHOW_IMPORT_STATUS' | translate}}</span>
    </a>
  </nav>
<div class="company__subpage-wrapper">
  <router-outlet></router-outlet>
</div>

</div>
