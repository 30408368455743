import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewContainerRef } from '@angular/core';
import { clone, DialogService, PanelService } from '@dc/shared';
import { TranslateService } from '@ngx-translate/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  DEFAULT_REQUEST_PARAMS,
  DeletedEnum,
  PAGE_SIZE_OPTIONS,
  User,
  UsersRequestParams,
  UsersResponse,
  UserTypeEnum,
  UserTypeIcon,
} from '@dc/data-models';
import { UsersService } from '../services/users.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'dc-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements AfterViewInit {
  public displayedColumns: string[] = ['fullName', 'type', 'username', 'email', 'phone', 'actions'];
  public dataSource: User[] = [];
  public totalCount = 0;
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public usersRequestParams: UsersRequestParams = clone(DEFAULT_REQUEST_PARAMS);

  public user!: User;
  public userTypes = [
    {
      value: UserTypeEnum.CREDITOR,
      icon: UserTypeIcon[UserTypeEnum.CREDITOR],
    },
    {
      value: UserTypeEnum.COLLECTOR,
      icon: UserTypeIcon[UserTypeEnum.COLLECTOR],
    },
  ];

  public readonly userTypeIcon = UserTypeIcon;

  constructor(
    private usersService: UsersService,
    private dialogService: DialogService,
    private panelService: PanelService,
    private translateService: TranslateService,
    private vcf: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  public ngAfterViewInit(): void {
    this.getListData();
  }

  public getListData(): void {
    this.usersService.getList(this.usersRequestParams).subscribe((res: UsersResponse) => {
      this.dataSource = res.users;
      this.totalCount = res.totalCount;
      this.cd.detectChanges();
    });
  }

  public openSidePanel(templateRef: TemplateRef<any>, user?: User, event?: Event): void {
    event?.stopPropagation();
    if (user) {
      this.user = user;
    }
    const portal = new TemplatePortal(templateRef, this.vcf);
    this.panelService.open(portal);
  }

  public deletedFilterChanged(event: MatSlideToggleChange): void {
    this.usersRequestParams.page = 0;
    this.usersRequestParams.deleted = event.checked ? DeletedEnum.ALL : DeletedEnum.NOT_DELETED;
    this.getListData();
  }

  public remove(user: User, event: Event): void {
    event.stopPropagation();
    const dialog = this.dialogService.openConfirmDialog(
      this.translateService.instant('CONFIRM_DIALOG.MSG', {
        name: user.name + ' ' + user.surname + ' (' + user.username + ')',
      })
    );

    dialog
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.usersService.remove(user.id);
          } else {
            return of(false);
          }
        })
      )
      .subscribe(() => {
        this.getListData();
      });
  }

  public onUserAdded(): void {
    this.getListData();
  }

  public elementClicked(user: User): void {
    this.router.navigate(['users', user.id]);
  }

  filterUserTypes() {
    console.log(this.usersRequestParams);
    // this.requestParams['userType'] =
  }
}
