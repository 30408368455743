import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const getAuthLoaded = createSelector(getAuthState, (state: AuthState) => state.loaded);

export const getAuthError = createSelector(getAuthState, (state: AuthState) => state.error);

export const getAuthUser = createSelector(getAuthState, (state: AuthState) => state.user);

export const getAuthCompany = createSelector(getAuthState, (state: AuthState) => state.company);

export const getAuthSession = createSelector(getAuthState, (state: AuthState) => state.session);

export const isRegisterEmailSent = createSelector(getAuthState, (state: AuthState) => state.registrationEmailSent);

export const accountActivateStatus = createSelector(getAuthState, (state: AuthState) => state.activateStatus);
