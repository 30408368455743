import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import {
  CompaniesResponse,
  Company,
  CompanyResponse,
  DEFAULT_REQUEST_PARAMS,
  ImportDebtsResponse,
  NCR,
  RequestParams,
} from '@dc/data-models';
import { map, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { clone } from '@dc/shared';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  private importFileListChangedSubject = new Subject<void>();

  constructor(private http: HttpClient) {}

  public getList(params: RequestParams = clone(DEFAULT_REQUEST_PARAMS)): Observable<CompaniesResponse> {
    return this.http.get<CompaniesResponse>(environment.apiUrl + '/companies', { params });
  }

  public get(id: string): Observable<CompanyResponse> {
    return this.http.get<CompanyResponse>(environment.apiUrl + '/companies/' + id).pipe(
      map((companyResponse) => {
        // @TODO remove the below parsing code when NCR format is agreed with backend
        if ((<NCR>companyResponse.company.companyForm.ncr)?.court) {
          return companyResponse;
        } else if (companyResponse.company.companyForm.ncr) {
          companyResponse.company.companyForm.ncr = JSON.parse(
            <string>(<unknown>companyResponse.company.companyForm.ncr)
          );
          return companyResponse;
        } else {
          return companyResponse;
        }
      })
    );
  }

  public remove(id: string): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/companies/' + id);
  }

  public create(companyData: Company): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/companies', companyData);
  }

  public update(companyId: string, companyData: Company): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/companies/' + companyId, companyData);
  }

  public importDebts(companyId: string, file: File): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const params = new HttpParams();

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    const options = {
      params: params,
      headers: headers,
      reportProgress: true,
    };

    return this.http
      .post(environment.apiUrl + '/companies/' + companyId + '/importDebts', formData, options)
      .pipe(tap(() => this.importFileListChange()));
  }

  public getImportDebts(companyId: string, params: RequestParams): Observable<ImportDebtsResponse> {
    return this.http.get<ImportDebtsResponse>(environment.apiUrl + '/companies/' + companyId + '/importDebts', {
      params,
    });
  }

  public importFileListChanged(): Observable<void> {
    return this.importFileListChangedSubject.asObservable();
  }

  public importFileListChange(): void {
    this.importFileListChangedSubject.next();
  }
}
