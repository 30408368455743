<div class="menu">
  <div class="menu__header">
    <div class="menu__header-title">
      <h2>
        <ng-content select="[header]"></ng-content>
        <sup class="menu__header-version">
          <ng-content select="[version]"></ng-content>
        </sup>
      </h2>

    </div>
    <div>
      <ng-content select="[user-info]"></ng-content>
    </div>
  </div>

  <div class="menu__content">
    <mat-nav-list>

      <ng-container *ngFor="let menuItem of menuItems">
        <mat-divider></mat-divider>
        <a *ngIf="!menuItem.disabled" class="menu__item" mat-list-item routerLinkActive="active" [routerLink]="menuItem.routerLink">
          <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">{{menuItem.icon}}</mat-icon>
          {{menuItem.labelKey | translate}}
        </a>
        <a *ngIf="menuItem.disabled" class="menu__item menu__item--disabled" mat-list-item [disabled]="true">
          <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">{{menuItem.icon}}</mat-icon>
          {{menuItem.labelKey | translate}}
        </a>
      </ng-container>
      <mat-divider></mat-divider>

    </mat-nav-list>

  </div>
  <span class="menu__spacer"></span>

  <div class="menu__footer">
    <span>© 2022 {{'APP.COMPANY' | translate}}</span>
    <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener">Maio Software House</a></span>
  </div>
  </div>
