import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as DebtsActions from './debts.actions';
import { catchError, EMPTY, exhaustMap, map, Observable, of, tap } from 'rxjs';
import { Action } from '@ngrx/store';
import { DebtsService } from '../../services/debts.service';
import { DebtResponse, DebtsResponse } from './debts.models';
import { ToastService } from '@dc/shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DebtsEffects {
  public init$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.initDebts),
        exhaustMap(() => {
          return this.debtsService.getList().pipe(
            map((debtsResponse: DebtsResponse) =>
              DebtsActions.loadDebtsSuccess({
                debts: debtsResponse.debts,
                totalCount: debtsResponse.totalCount,
              })
            ),
            catchError((error) => of(DebtsActions.loadDebtsFailure({ error })))
          );
        })
      )
  );

  public loadDebts$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.loadDebts),
        exhaustMap((state) => {
          const params = state.requestParams;
          return this.debtsService.getList(params).pipe(
            map((debtsResponse: DebtsResponse) =>
              DebtsActions.loadDebtsSuccess({
                debts: debtsResponse.debts,
                totalCount: debtsResponse.totalCount,
              })
            ),
            catchError((error) => of(DebtsActions.loadDebtsFailure({ error })))
          );
        })
      )
  );

  public initDebtsPublic$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.initDebtsPublic),
        exhaustMap(() => {
          return this.debtsService.getCollectorPublicList().pipe(
            map((debtsResponse: DebtsResponse) =>
              DebtsActions.loadDebtsPublicSuccess({
                debts: debtsResponse.debts,
                totalCount: debtsResponse.totalCount,
              })
            ),
            catchError((error) => {
              console.error('Error', error);
              return of(DebtsActions.loadDebtsPublicFailure({ error }));
            })
          );
        })
      )
  );

  public loadDebtsPublic$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.loadDebtsPublic),
        exhaustMap((state) => {
          const params = state.requestParams;
          return this.debtsService.getCollectorPublicList(params).pipe(
            map((debtsResponse: DebtsResponse) =>
              DebtsActions.loadDebtsPublicSuccess({
                debts: debtsResponse.debts,
                totalCount: debtsResponse.totalCount,
              })
            ),
            catchError((error) => {
              console.error('Error', error);
              return of(DebtsActions.loadDebtsPublicFailure({ error }));
            })
          );
        })
      )
  );

  public loadDebt$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.loadDebt),
        exhaustMap((state) => {
          return this.debtsService.getDebt(state.debtId).pipe(
            map((debtResponse: DebtResponse) =>
              DebtsActions.loadDebtSuccess({
                debt: debtResponse.debts,
              })
            ),
            catchError((error) => of(DebtsActions.loadDebtFailure({ error })))
          );
        })
      )
  );

  public assignDebt$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.assignDebt),
        exhaustMap((state) => {
          return this.debtsService.assignDebt(state.debtId).pipe(
            map(() => DebtsActions.assignDebtSuccess({ debtId: state.debtId })),
            catchError((error: HttpErrorResponse) => of(DebtsActions.assignDebtFailure({ error: error.error })))
          );
        })
      )
  );

  public assignDebtSuccess$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.assignDebtSuccess),
        map((state) => {
          this.toastService.success('DEBTS_MAP.COLLECTOR_ASSIGNED_TO_DEBT');
          return DebtsActions.initDebts();
          // return DebtsActions.loadDebt({ debtId: state.debtId });
        })
      )
  );

  public assignDebtFailure$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtsActions.assignDebtFailure),
        tap((err) => {
          this.toastService.error('DEBTS_MAP.' + (<any>err).error?.code);
        }),
        exhaustMap(() => EMPTY)
      )
  );

  constructor(
    private readonly actions$: Actions,
    private debtsService: DebtsService,
    private toastService: ToastService
  ) {}
}
