export const HYPHEN = '-';

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function removeEmpty<T>(obj: T): T {
  return Object.entries(<never>obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), <T>{});
}
