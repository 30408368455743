import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivateInput, AUTH_MODULE_CONFIG, AuthModuleConfig, BusinessActivityTypeEnum } from '@dc/data-models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_META, MatchStringValidator } from '@dc/shared';
import { ActivateStatusEnum, AuthFacade, CompanyInfo, Profile } from '@dc/auth';
import { combineLatest, map, tap } from 'rxjs';

@Component({
  selector: 'dc-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateComponent implements OnInit, OnDestroy {
  public readonly activateStatusEnum = ActivateStatusEnum;
  public readonly companyFormsEnum = BusinessActivityTypeEnum;

  public companyInfoForm!: FormGroup;
  public activateForm!: FormGroup;

  public appName = this.authModuleConfig.appNameKey;
  public version = APP_META.version;

  public emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  public passwordCtrl = new FormControl('', [Validators.required, Validators.minLength(5)]);
  public passwordRepeatCtrl = new FormControl('', [Validators.required]);
  public tokenCtrl = new FormControl('', [Validators.required]);
  public taxIdCtrl = new FormControl('', [Validators.required]);

  public selectedCompanyForm: BusinessActivityTypeEnum = BusinessActivityTypeEnum.SOLE_PROPRIETORSHIP;

  public vm$ = combineLatest([
    this.route.queryParams.pipe(
      map((queryParams) => {
        const token = queryParams['token'];
        this.tokenCtrl.setValue(token);
        console.log('init activation page in vm');
        if (token) {
          this.authFacade.initActivationPage(token);
        }
        return token;
      })
    ),
    this.authFacade.accountActivateStatus$,
    this.authFacade.user$.pipe(
      tap((user) => {
        if (user) {
          this.emailCtrl.setValue(user.email);
        }
      })
    ),
    this.authFacade.company$,
  ]).pipe(
    map(
      ([token, activateStatus, user, company]): {
        token: string;
        activateStatus: ActivateStatusEnum | null;
        user: Profile | null;
        company: CompanyInfo | null;
      } => ({
        token,
        activateStatus,
        user,
        company,
      })
    )
  );

  constructor(
    @Inject(AUTH_MODULE_CONFIG) private authModuleConfig: AuthModuleConfig,
    private authFacade: AuthFacade,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.companyInfoForm = this.formBuilder.group({
      email: this.emailCtrl,
      taxId: this.taxIdCtrl,
    });

    this.activateForm = this.formBuilder.group(
      {
        password: this.passwordCtrl,
        passwordRepeat: this.passwordRepeatCtrl,
        token: this.tokenCtrl,
      },
      {
        validators: MatchStringValidator('password', 'passwordRepeat'),
      }
    );
  }

  public ngOnDestroy(): void {
    this.activateForm.reset();
  }

  public getCompanyInfo(): void {
    if (!this.companyInfoForm.valid) {
      return;
    }
    this.authFacade.getCompany(<string>this.tokenCtrl.value, <string>this.taxIdCtrl.value);
  }

  public activate(): void {
    if (!this.activateForm.valid) {
      return;
    }
    const activateInput: ActivateInput = {
      token: this.tokenCtrl.value,
      taxId: this.taxIdCtrl.value,
      formType: this.selectedCompanyForm,
      password: this.passwordCtrl.value,
      email: this.emailCtrl.value,
    };

    this.authFacade.activate(activateInput);
  }
}
