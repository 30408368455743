import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AUTH_MODULE_CONFIG, Authenticate, AuthModuleConfig } from '@dc/data-models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { APP_META } from '@dc/shared';
import { AuthFacade } from '@dc/auth';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'dc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm!: FormGroup;
  public appName = this.authModuleConfig.appNameKey;
  public version = APP_META.version;

  public vm$ = combineLatest([this.authFacade.error$]).pipe(map(([error]) => ({ error })));

  private state!: string | null;

  constructor(
    @Inject(AUTH_MODULE_CONFIG) private authModuleConfig: AuthModuleConfig,
    private authService: AuthService,
    private authFacade: AuthFacade,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    if (this.route.snapshot?.queryParams['state']) {
      this.state = this.route.snapshot?.queryParams['state'];
    }
  }

  public ngOnDestroy(): void {
    this.loginForm.reset();
  }

  public login(): void {
    if (!this.loginForm.valid) {
      return;
    }
    const authenticate: Authenticate = {
      username: this.loginForm.getRawValue().username,
      password: this.loginForm.getRawValue().password,
    };

    this.authFacade.login(authenticate);
  }
}
