<div class="container flex justify-center content-center" *ngIf="vm$ | async as vm">
  <mat-card class="h-auto mx-4 min-w-xl md:max-w-2xl place-self-center">

    <mat-card-header>
      <h1 class="m-8 w-full text-center font-medium">{{ 'APP.NAME.' + appName | uppercase | translate }}</h1>
    </mat-card-header>

    <mat-card-content *ngIf="vm.token && vm.activateStatus !== activateStatusEnum.ACTIVATE_SUCCESS">
      <form class="flex flex-col mx-8 md:mx-12" name="displayOnlyForm" autocomplete="off">
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.NAME' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="name"
            name="name"
            [value]="vm.user?.name"
            disabled
          />
          <mat-error>{{ 'AUTH.ERRORS.TAX_ID' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.SURNAME' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="surname"
            name="surname"
            [value]="vm.user?.surname"
            disabled
          />
          <mat-error>{{ 'AUTH.ERRORS.TAX_ID' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.EMAIL' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="email"
            name="email"
            [value]="vm.user?.email"
            disabled
          />
          <mat-error>{{ 'AUTH.ERRORS.EMAIL' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.COMPANIES.COMPANY_FORM' | translate}}</mat-label>
          <mat-select [disabled]="true" [value]="selectedCompanyForm">
            <mat-option [value]="companyFormsEnum.SOLE_PROPRIETORSHIP">
              {{'AUTH.COMPANIES.COMPANY_FORMS.SOLE_PROPRIETORSHIP' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <form *ngIf="!vm.company" class="flex flex-col mx-8 md:mx-12" name="companyInfoForm" [formGroup]="companyInfoForm" novalidate (ngSubmit)="getCompanyInfo()" autocomplete="off">
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.TAX_ID' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="taxId"
            name="taxId"
            formControlName="taxId"
            mask="000 000 00 00"
          />
          <mat-error>{{ 'AUTH.ERRORS.TAX_ID' | translate }}</mat-error>
        </mat-form-field>
        <div class="mb-12">
          <button
            mat-flat-button
            color="accent"
            class="w-full"
            type="submit"
          >
            <span class="submit-button-inner">
            {{ 'AUTH.GET_INFORMATION_ABOUT_ACCOUNT' | translate }}
            </span>
          </button>
        </div>
      </form>
      <form *ngIf="vm.company" class="flex flex-col mx-8 md:mx-12" name="displayOnlyForm" autocomplete="off">
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.TAX_ID' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            name="taxIdReadonly"
            id="taxIdReadonly"
            mask="000 000 00 00"
            [ngModel]="vm.company.taxId"
            disabled
          />
        </mat-form-field>
      </form>
      <form *ngIf="vm.company" class="flex flex-col mx-8 md:mx-12" name="activateForm" [formGroup]="activateForm" novalidate (ngSubmit)="activate()" autocomplete="off">
        <mat-form-field>
          <mat-label>{{ 'AUTH.PASSWORD' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="password"
            name="password"
            formControlName="password"
            type="password"
          />
          <mat-error>{{ 'AUTH.ERRORS.PASSWORD' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mb-8">
          <mat-label>{{ 'AUTH.PASSWORD_REPEAT' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="passwordRepeat"
            name="passwordRepeat"
            formControlName="passwordRepeat"
            type="password"
          />
          <mat-error>{{ 'AUTH.ERRORS.PASSWORD_REPEAT' | translate }}</mat-error>
        </mat-form-field>

        <div class="mb-12">
          <button
            mat-flat-button
            color="accent"
            class="w-full"
            type="submit"
          >
            <span class="submit-button-inner">
            {{ 'AUTH.ACTIVATE' | translate }}
            </span>
          </button>
        </div>
      </form>
      <div class="flex justify-between text-xs text-slate-500">
        <span>© 2022 Odzyskator sp. z o.o. <strong>v{{ version }}</strong></span>
        <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener" rel="nofollow" rel="noreferrer">Maio Software House</a></span>
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="vm.activateStatus === activateStatusEnum.ACTIVATE_SUCCESS">
      <p class="text-center pb-8">
        {{ 'AUTH.MESSAGES.ACTIVATION_SUCCESS' | translate }}
      </p>
      <div class="text-center mt-4 mb-8">
        <p>
          <a routerLink="/auth/login">{{ 'AUTH.SIGN_IN' | translate }}</a>
        </p>
      </div>
      <div class="flex justify-between text-xs text-slate-500">
        <span>© 2022 Odzyskator sp. z o.o. <strong>v{{ version }}</strong></span>
        <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener" rel="nofollow" rel="noreferrer">Maio Software House</a></span>
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="!vm.token">
      <p class="text-center pb-8">
        {{ 'AUTH.ERRORS.NO_TOKEN_AVAILABLE' | translate }}
      </p>
      <div class="flex justify-between text-xs text-slate-500">
        <span>© 2022 Odzyskator sp. z o.o. <strong>v{{ version }}</strong></span>
        <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener" rel="nofollow" rel="noreferrer">Maio Software House</a></span>
      </div>
    </mat-card-content>

  </mat-card>
</div>
