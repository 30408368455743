import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as DebtsActions from './debts.actions';
import { DebtsEntity, DebtsRequestParams, DEFAULT_DEBTS_REQUEST_PARAMS } from './debts.models';
import { clone } from '@dc/shared';
import { loadDebtFailure, loadDebtsPublicSuccess } from './debts.actions';

export const DEBTS_FEATURE_KEY = 'debts';

export interface DebtsState extends EntityState<DebtsEntity> {
  selectedId?: string; // which Debts record has been selected
  requestParams: DebtsRequestParams;
  totalCount: number;
  loaded: boolean; // has the Debts list been loaded
  error?: string | null; // last known error (if any)
}

export interface DebtsPartialState {
  readonly [DEBTS_FEATURE_KEY]: DebtsState;
}

export const debtsAdapter: EntityAdapter<DebtsEntity> = createEntityAdapter<DebtsEntity>();

export const initialDebtsState: DebtsState = debtsAdapter.getInitialState({
  // set initial required properties
  requestParams: clone(DEFAULT_DEBTS_REQUEST_PARAMS),
  totalCount: 0,
  loaded: false,
});

const reducer = createReducer(
  initialDebtsState,
  on(DebtsActions.initDebts, (state) => ({ ...state, loaded: false, error: null })),
  on(DebtsActions.initDebtsPublic, (state) => ({ ...state, loaded: false, error: null })),

  on(DebtsActions.loadDebts, (state, payload) => {
    return { ...state, requestParams: payload.requestParams, loaded: false, error: null };
  }),
  on(DebtsActions.loadDebtsPublic, (state, payload) => ({
    ...state,
    requestParams: payload.requestParams,
    loaded: false,
    error: null,
  })),
  on(DebtsActions.loadDebtsSuccess, (state, { debts, totalCount }) => {
    return debtsAdapter.setAll(debts, { ...state, loaded: true, totalCount });
  }),
  on(DebtsActions.loadDebtsPublicSuccess, (state, { debts, totalCount }) => {
    return debtsAdapter.setAll(debts, { ...state, loaded: true, totalCount });
  }),
  on(DebtsActions.loadDebtsFailure, (state, { error }) => ({ ...state, error })),
  on(DebtsActions.loadDebtsPublicFailure, (state, { error }) => ({ ...state, error })),
  on(DebtsActions.selectDebt, (state, payload) => ({ ...state, selectedId: payload.debtId })),
  on(DebtsActions.loadDebt, (state) => ({ ...state })),
  on(DebtsActions.loadDebtSuccess, (state, payload) => {
    return debtsAdapter.setOne(payload.debt, { ...state });
  }),
  on(DebtsActions.loadDebtFailure, (state, { error }) => ({ ...state, error }))
);

export function debtsReducer(state: DebtsState | undefined, action: Action) {
  return reducer(state, action);
}
