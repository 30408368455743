/**
 * Interface for the 'Auth' data
 */
import { Address, Administrator, ResponseOK } from '@dc/data-models';

export interface ProfileResponse {
  profile: Profile;
}

export interface AdministratorProfileResponse extends ResponseOK {
  profile: Administrator;
}

export interface InactiveAccountResponse extends ResponseOK {
  user: Profile;
}

export interface CompanyInfoResponse extends ResponseOK {
  company: CompanyInfo;
}

export interface Profile {
  id: string;
  name: string;
  surname: string;
  email: string;
  phone: string | null;
  profile: unknown | null;
  bankDetails: unknown;
  personNumberId: string | null;
  createdDate: Date;
  updatedDate: Date;
}

export enum CompanyStatusEnum {
  ACTIVE = 'AKTYWNY',
}

export interface CompanyInfo {
  address: Address;
  name: string;
  pca: string[];
  status: CompanyStatusEnum;
  taxId: string;
}

export enum ActivateStatusEnum {
  INIT = 'INIT',
  TAX_ID = 'TAX_ID',
  ACTIVATE = 'ACTIVATE',
  ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS',
  ACTIVATE_ERROR = 'ACTIVATE_ERROR',
}
