import { InjectionToken } from '@angular/core';

export interface AuthModuleConfig {
  appNameKey: string;
  appName: string;
  apiUrl: string;
  apiPublicUrl?: string;
}

export const AUTH_MODULE_CONFIG = new InjectionToken('Auth Module Config');
