import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COLLECTOR_DEBTS_FEATURE_KEY, CollectorDebtsState, collectorDebtsAdapter } from './collector-debts.reducer';
import { DebtsState } from '../debts/debts.reducer';
import { getDebtsState } from '../debts/debts.selectors';

// Lookup the 'CollectorDebts' feature state managed by NgRx
export const getCollectorDebtsState = createFeatureSelector<CollectorDebtsState>(COLLECTOR_DEBTS_FEATURE_KEY);

const { selectAll, selectEntities } = collectorDebtsAdapter.getSelectors();

export const getCollectorDebtsLoaded = createSelector(
  getCollectorDebtsState,
  (state: CollectorDebtsState) => state.loaded
);

export const getCollectorDebtsError = createSelector(
  getCollectorDebtsState,
  (state: CollectorDebtsState) => state.error
);

export const getAllCollectorDebts = createSelector(getCollectorDebtsState, (state: CollectorDebtsState) =>
  selectAll(state)
);

export const getCollectorDebtsRequestParams = createSelector(
  getCollectorDebtsState,
  (state: CollectorDebtsState) => state.requestParams
);

export const getCollectorDebtsTotalCount = createSelector(
  getCollectorDebtsState,
  (state: CollectorDebtsState) => state.totalCount
);

export const getCollectorDebtsEntities = createSelector(getCollectorDebtsState, (state: CollectorDebtsState) =>
  selectEntities(state)
);

export const getSelectedCollectorDebtId = createSelector(
  getCollectorDebtsState,
  (state: CollectorDebtsState) => state.selectedId
);

export const getSelectedCollectorDebt = createSelector(
  getCollectorDebtsEntities,
  getSelectedCollectorDebtId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
