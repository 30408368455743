<div class="container mx-auto">
  <mat-card class="login-panel">

    <mat-card-header class="login-panel__header">
      <h1 class="login-panel__name"> {{ 'APP.NAME.'+appName | uppercase | translate }}</h1>
    </mat-card-header>

    <mat-card-content class="login-panel__content" *ngIf="!(isRegisterEmailSent$ | async)">
      <form class="login-form" name="registerForm" [formGroup]="registerForm" novalidate (ngSubmit)="register()" autocomplete="off">
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.NAME' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="name"
            name="name"
            formControlName="name"
          />
          <mat-error>{{ 'AUTH.ERRORS.NAME' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.SURNAME' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="surname"
            name="surname"
            formControlName="surname"
          />
          <mat-error>{{ 'AUTH.ERRORS.SURNAME' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.PHONE' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="phone"
            name="phone"
            formControlName="phone"
            mask="000 000 000"
            prefix="+48 "
          />
          <mat-error>{{ 'AUTH.ERRORS.PHONE' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'AUTH.EMAIL' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="email"
            name="email"
            formControlName="email"
          />
          <mat-error>{{ 'AUTH.ERRORS.EMAIL' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'AUTH.EMAIL_REPEAT' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            id="emailRepeat"
            name="emailRepeat"
            formControlName="emailRepeat"
          />
          <mat-error>{{ 'AUTH.ERRORS.EMAIL_REPEAT' | translate }}</mat-error>
        </mat-form-field>

        <div class="mb-4 mt-6">
          <button
            mat-flat-button
            color="accent"
            class="submit-button w-full"
            type="submit"
          >
            <span class="submit-button-inner">
            {{ 'AUTH.REGISTER' | translate }}
            </span>
          </button>
        </div>

        <div class="text-center mt-4 mb-8">
          <p>{{ 'AUTH.HAVE_AN_ACCOUNT' | translate }}<br>
            <a routerLink="/auth/login">{{ 'AUTH.SIGN_IN' | translate }}</a>
          </p>
        </div>

        <div class="footer">
          <span>© 2022 Odzyskator sp. z o.o. <strong>v{{version}}</strong></span>
          <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener" rel="nofollow" rel="noreferrer">Maio Software House</a></span>
        </div>

      </form>
    </mat-card-content>

    <mat-card-content class="login-panel__content" *ngIf="isRegisterEmailSent$ | async">
      <p class="text-center">
        {{ 'AUTH.REGISTER_EMAIL_SENT' | translate }}<br>
        <strong>{{ emailCtrl.value }}</strong>
      </p>
      <div class="text-center mt-4 mb-8">
        <p>
          <a routerLink="/auth/login">{{ 'AUTH.SIGN_IN' | translate }}</a>
        </p>
      </div>
    </mat-card-content>

  </mat-card>
</div>
