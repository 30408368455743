import { NgModule } from '@angular/core';
import { CompaniesComponent } from './components/companies/companies.component';
import { SharedModule } from '@dc/shared';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyAddressComponent } from './components/company-address/company-address.component';
import { ImportDebtsComponent } from './components/import-debts/import-debts.component';
import { ImportDebtsLastComponent } from './components/import-debts-last/import-debts-last.component';
import { ImportDebtsStatusComponent } from './components/import-debts-status/import-debts-status.component';

@NgModule({
  declarations: [
    CompaniesComponent,
    AddCompanyComponent,
    CompanyDetailsComponent,
    CompanyAddressComponent,
    ImportDebtsComponent,
    ImportDebtsLastComponent,
    ImportDebtsStatusComponent,
  ],
  imports: [SharedModule],
})
export class CompaniesModule {}
