import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ImportDebtsResponse, ImportFile, RequestParams } from '@dc/data-models';
import { CompaniesService } from '../../services/companies.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'dc-import-debts-last',
  templateUrl: './import-debts-last.component.html',
  styleUrls: ['./import-debts-last.component.scss'],
})
export class ImportDebtsLastComponent implements OnInit, AfterViewInit, OnDestroy {
  public requestParams: RequestParams = { page: 0, limit: 1 };
  public importFile!: ImportFile | null;

  private companyId!: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private companiesService: CompaniesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    delete this.requestParams.deleted;
  }

  public ngOnInit(): void {
    if (!(<ActivatedRouteSnapshot>this.route.snapshot.parent).params['id']) {
      throw new Error('Company id is null');
    }

    this.companyId = (<ActivatedRouteSnapshot>this.route.snapshot.parent).params['id'];
    this.companiesService
      .importFileListChanged()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.getListData());
  }

  public ngAfterViewInit(): void {
    this.getListData();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getListData(): void {
    this.companiesService.getImportDebts(this.companyId, this.requestParams).subscribe((res: ImportDebtsResponse) => {
      this.importFile = res.importFiles?.length ? res.importFiles[0] : null;
      this.cd.detectChanges();
    });
  }
}
