import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppComponent } from './app.component';
import { HttpLoaderFactory, SharedModule } from '@dc/shared';
import { AuthModuleConfig, DataModelsModule } from '@dc/data-models';
import { environment } from '../environments/environment';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { HeaderComponent } from './components/main-container/header/header.component';
import { appRoutes } from './app.routes';
import { AuthModule } from '@dc/auth';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AdministratorsModule } from './modules/administrators/administrators.module';
import { UsersModule } from './modules/users/users.module';
import { CompaniesModule } from './modules/companies/companies.module';
import { DebtsListModule } from './modules/debts/debts-list.module';
import { DebtorsModule } from './modules/debtors/debtors.module';
import { DebtsModule } from '@dc/debts';
import { DataDebtorsModule } from '@dc/debtors';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const authModuleConfig: AuthModuleConfig = {
  appNameKey: 'ADMINISTRATOR',
  appName: 'admin',
  apiUrl: environment.apiUrl,
};

@NgModule({
  declarations: [AppComponent, MainContainerComponent, HeaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpBackend] },
      defaultLanguage: 'pl',
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),

    DataModelsModule,
    SharedModule.forRoot(),
    AuthModule.forRoot(authModuleConfig),
    DebtsModule.forRoot(environment),
    DataDebtorsModule.forRoot(environment),

    AdministratorsModule,
    DebtsListModule,
    DebtorsModule,
    CompaniesModule,
    UsersModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
