import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as CollectorDebtsActions from './collector-debts.actions';
import { catchError, delay, EMPTY, exhaustMap, map, Observable, of, tap } from 'rxjs';
import { DebtsService } from '../../services/debts.service';
import { Action } from '@ngrx/store';
import * as DebtsActions from '../debts/debts.actions';
import { CollectorDebtsResponse, DebtsResponse } from '@dc/debts';
import { ToastService } from '@dc/shared';

@Injectable()
export class CollectorDebtsEffects {
  init$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.initCollectorDebts),
        exhaustMap((state) => {
          return this.debtsService.getCollectorDebts().pipe(
            map((collectorDebtsResponse) => {
              return CollectorDebtsActions.loadCollectorDebtsSuccess({
                collectorDebts: collectorDebtsResponse.debts,
                totalCount: collectorDebtsResponse.totalCount,
              });
            }),
            catchError((error) => {
              return of(CollectorDebtsActions.loadCollectorDebtsFailure({ error }));
            })
          );
        })
      )
  );

  public loadDebts$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.loadCollectorDebts),
        exhaustMap((state) => {
          const params = state.requestParams;
          return this.debtsService.getCollectorDebts(params).pipe(
            map((debtsResponse: CollectorDebtsResponse) =>
              CollectorDebtsActions.loadCollectorDebtsSuccess({
                collectorDebts: debtsResponse.debts,
                totalCount: debtsResponse.totalCount,
              })
            ),
            catchError((error) => of(CollectorDebtsActions.loadCollectorDebtsFailure({ error })))
          );
        })
      )
  );

  loadCollectorDebtsFailure$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.loadCollectorDebtsFailure),
        tap((err) => {
          this.toastService.error('ASSIGNED_DEBTS.ERRORS.LOAD_DEBTS_ERROR');
        }),
        exhaustMap(() => EMPTY)
      )
  );

  removeAssignment$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.removeAssignment),
        exhaustMap((state) => {
          return this.debtsService.removeAssignment(state.debtId).pipe(
            // TODO removal of the assignment lasts some time if we ask for new list just in 0 seconds
            //  the removed assignment is still on the list, so we need to think of some more bulletproof solution then delay
            //  or maybe just "optimistic" removal on the frontend side
            delay(1000),
            map(() => {
              return CollectorDebtsActions.removeAssignmentSuccess();
            }),
            catchError((error) => of(CollectorDebtsActions.removeAssignmentFailure({ error })))
          );
        })
      )
  );

  removeAssignmentSuccess$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.removeAssignmentSuccess),
        map((err) => {
          this.toastService.success('ASSIGNED_DEBTS.MESSAGES.ASSIGNMENT_REMOVED');
          return CollectorDebtsActions.initCollectorDebts();
        })
      )
  );

  removeAssignmentFailure$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.removeAssignmentFailure),
        tap((err) => {
          this.toastService.error('ASSIGNED_DEBTS.ERRORS.ASSIGNMENT_REMOVAL_ERROR');
        }),
        exhaustMap(() => EMPTY)
      )
  );

  acceptAssignment$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.acceptAssignment),
        exhaustMap((state) => {
          return this.debtsService.acceptAssignment(state.debtId).pipe(
            // TODO removal of the assignment lasts some time if we ask for new list just in 0 seconds
            //  the removed assignment is still on the list, so we need to think of some more bulletproof solution then delay
            //  or maybe just "optimistic" removal on the frontend side
            delay(1000),
            map(() => {
              return CollectorDebtsActions.acceptAssignmentSuccess();
            }),
            catchError((error) => of(CollectorDebtsActions.acceptAssignmentFailure({ error })))
          );
        })
      )
  );

  acceptAssignmentSuccess$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.acceptAssignmentSuccess),
        map((err) => {
          this.toastService.success('ASSIGNED_DEBTS.MESSAGES.ASSIGNMENT_CONFIRMED');
          return CollectorDebtsActions.initCollectorDebts();
        })
      )
  );

  acceptAssignmentFailure$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorDebtsActions.acceptAssignmentFailure),
        tap((err) => {
          this.toastService.error('ASSIGNED_DEBTS.ERRORS.ASSIGNMENT_CONFIRMATION_ERROR');
        }),
        exhaustMap(() => EMPTY)
      )
  );

  constructor(
    private readonly actions$: Actions,
    private debtsService: DebtsService,
    private toastService: ToastService
  ) {}
}
