import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, exhaustMap, Observable, switchMap, take, tap, throwError, withLatestFrom } from 'rxjs';
import { AuthFacade, AuthService } from '@dc/auth';
import { Session } from '@dc/data-models';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private authFacade: AuthFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appSessionHeaderName = `X-Session-${this.authService.authModuleConfig.appName.toLowerCase()}`;

    return this.authFacade.session$.pipe(
      take(1),
      exhaustMap((session: Session | null) => {
        if (!session) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
          withCredentials: true,
          headers: req.headers.append(appSessionHeaderName, session ? <string>session.id : 'NOSESSION'),
        });
        return next.handle(modifiedReq);
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            this.authService.forceLogout();
          }
        }
        return throwError(err);
      })
    );
  }
}
