import { createAction, props } from '@ngrx/store';
import { CollectorMapEntity } from './collector-map.models';

export const initCollectorMap = createAction('[CollectorMap Page] Init');

export const loadCollectorMapSuccess = createAction(
  '[CollectorMap/API] Load CollectorMap Success',
  props<{ collectorMap: CollectorMapEntity[] }>()
);

export const loadCollectorMapFailure = createAction(
  '[CollectorMap/API] Load CollectorMap Failure',
  props<{ error: any }>()
);
