import { Injectable, ErrorHandler, NgZone, Injector, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { noop } from 'rxjs';
import { ToastService } from './toast.service';

@Injectable()
export class ErrorService extends ErrorHandler {
  public serviceName = 'ErrorService';

  // Need to get ToastService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastService(): ToastService {
    return this.injector.get(ToastService);
  }

  constructor(private zone: NgZone, @Inject(Injector) private readonly injector: Injector) {
    super();
  }

  //TODO ogarnac globalny handler na HTTP i inne beldy,
  public override handleError(error: Error | HttpErrorResponse | string | any): void {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection ? error.rejection : error; // get the error object
    }
    if (error instanceof TypeError || error.error instanceof ErrorEvent) {
      this.showError(Error('Undefined client error'));
    } else if (typeof error === 'string') {
      this.showError(error);
    } else {
      this.handleHttpError(error);
      noop();
    }
    super.handleError(error);
  }

  private showError(message: any, type = 'error'): void {
    this.zone.run(() => {
      if (type === 'warn') {
        this.toastService.warn(message);
      } else if (typeof message === 'string') {
        this.toastService.error(message);
      } else {
        this.toastService.error('Unexpected error');
      }
    });
  }

  private handleHttpError(error: Error | HttpErrorResponse): void {
    if ((<HttpErrorResponse>error).error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      //TODO nie zaciagaja sie tlumaczenia jak nie ma sieci :)
      if (!navigator.onLine) {
        this.showError('MESSAGES.ERROR.NO_INTERNET_CONNECTION');
      }
    } else {
      // handle HTTP errors
      switch ((<HttpErrorResponse>error).status) {
        case 400:
          this.showError((<HttpErrorResponse>error).error.code);
          break;
        case 401:
          this.showError('MESSAGES.WARN.USER_OR_PASSWORD_ARE_INCORRECT', 'warn');
          break;
        case 403:
          this.showError('MESSAGES.ERROR.NO_SESSION_OR_RIGHTS');
          break;
        default:
          this.showError('Unexpected error');
      }
    }
  }
}
