import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as CollectorDebtsActions from './collector-debts.actions';
import {
  CollectorDebtsEntity,
  CollectorDebtsRequestParams,
  DEFAULT_COLLECTOR_DEBTS_REQUEST_PARAMS,
} from './collector-debts.models';
import { clone } from '@dc/shared';
import * as DebtsActions from '../debts/debts.actions';

export const COLLECTOR_DEBTS_FEATURE_KEY = 'collectorDebts';

export interface CollectorDebtsState extends EntityState<CollectorDebtsEntity> {
  selectedId?: string | number; // which CollectorDebts record has been selected
  requestParams: CollectorDebtsRequestParams;
  totalCount: number;
  loaded: boolean; // has the CollectorDebts list been loaded
  error?: string | null; // last known error (if any)
}

export interface CollectorDebtsPartialState {
  readonly [COLLECTOR_DEBTS_FEATURE_KEY]: CollectorDebtsState;
}

export const collectorDebtsAdapter: EntityAdapter<CollectorDebtsEntity> = createEntityAdapter<CollectorDebtsEntity>();

export const initialCollectorDebtsState: CollectorDebtsState = collectorDebtsAdapter.getInitialState({
  // set initial required properties
  requestParams: clone(DEFAULT_COLLECTOR_DEBTS_REQUEST_PARAMS),
  totalCount: 0,
  loaded: false,
});

const reducer = createReducer(
  initialCollectorDebtsState,
  on(CollectorDebtsActions.initCollectorDebts, (state) => ({ ...state, loaded: false, error: null })),
  on(CollectorDebtsActions.loadCollectorDebts, (state, payload) => ({
    ...state,
    loaded: false,
    requestParams: payload.requestParams,
    error: null,
  })),
  on(CollectorDebtsActions.loadCollectorDebtsSuccess, (state, { collectorDebts, totalCount }) =>
    collectorDebtsAdapter.setAll(collectorDebts, { ...state, loaded: true, error: null, totalCount })
  ),
  on(CollectorDebtsActions.loadCollectorDebtsFailure, (state, { error }) => ({ ...state, error })),
  on(CollectorDebtsActions.selectCollectorDebt, (state, payload) => ({ ...state, selectedId: payload.debtId }))
);

export function collectorDebtsReducer(state: CollectorDebtsState | undefined, action: Action) {
  return reducer(state, action);
}
