import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDataDebtors from './+state/debtors/debtors.reducer';
import { DebtorsEffects } from './+state/debtors/debtors.effects';
import { DebtorsFacade } from './+state/debtors/debtors.facade';
import { ENVIRONMENT, Environment } from '@dc/data-models';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDataDebtors.DATA_DEBTORS_FEATURE_KEY, fromDataDebtors.debtorsReducer),
    EffectsModule.forFeature([DebtorsEffects]),
  ],
  providers: [DebtorsFacade],
})
export class DataDebtorsModule {
  public static forRoot(environment: Environment): ModuleWithProviders<DataDebtorsModule> {
    return {
      ngModule: DataDebtorsModule,
      providers: [
        {
          provide: ENVIRONMENT,
          useValue: environment,
        },
      ],
    };
  }
}
