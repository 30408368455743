<h2 mat-dialog-title>{{'CONFIRM_DIALOG.HEADER' | translate}}</h2>
<div mat-dialog-content>
  <p [innerHTML]="data.question"></p>
</div>

<div mat-dialog-actions align="end">
  <button class="confirm-dialog-cancel" mat-flat-button mat-dialog-close type="button">
    {{'CONFIRM_DIALOG.CANCEL' | translate}}
  </button>
  <button class="confirm-dialog-btn" mat-flat-button color="warn" type="button" [mat-dialog-close]="true">
    {{'CONFIRM_DIALOG.CONFIRM' | translate}}
  </button>
</div>
