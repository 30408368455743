import { NgModule } from '@angular/core';
import { SharedModule } from '@dc/shared';
import { UsersComponent } from './components/users.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';

@NgModule({
  declarations: [UsersComponent, AddUserComponent, UserDetailsComponent],
  imports: [SharedModule],
})
export class UsersModule {}
