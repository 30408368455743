import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as DebtorsActions from './debtors.actions';
import { DebtorsService } from '../../services/debtors.service';
import { catchError, concatMap, map, Observable, of, switchMap } from 'rxjs';
import { Action } from '@ngrx/store';
import { DebtorsResponse } from './debtors.models';

@Injectable()
export class DebtorsEffects {
  public init$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtorsActions.initDebtors),
        switchMap(() => {
          return this.debtorsService.getList().pipe(
            map((debtorsResponse: DebtorsResponse) =>
              DebtorsActions.loadDebtorsSuccess({
                debtors: debtorsResponse.debtors,
                totalCount: debtorsResponse.totalCount,
              })
            )
          );
        }),
        catchError((error) => {
          console.log(error);
          return of(DebtorsActions.loadDebtorsFailure({ error }));
        })
      )
  );

  public loadDebtors$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(DebtorsActions.loadDebtors),
        concatMap((state) => {
          const params = state.requestParams;
          return this.debtorsService.getList(params).pipe(
            map((debtorsResponse: DebtorsResponse) =>
              DebtorsActions.loadDebtorsSuccess({
                debtors: debtorsResponse.debtors,
                totalCount: debtorsResponse.totalCount,
              })
            ),
            catchError((error) => {
              console.error('Error', error);
              return of(DebtorsActions.loadDebtorsFailure({ error }));
            })
          );
        })
      )
  );

  constructor(private readonly actions$: Actions, private debtorsService: DebtorsService) {}
}
