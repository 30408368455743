import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEBTS_FEATURE_KEY, DebtsState, debtsAdapter } from './debts.reducer';

// Lookup the 'Debts' feature state managed by NgRx
export const getDebtsState = createFeatureSelector<DebtsState>(DEBTS_FEATURE_KEY);

const { selectAll, selectEntities } = debtsAdapter.getSelectors();

export const getDebtsLoaded = createSelector(getDebtsState, (state: DebtsState) => state.loaded);

export const getDebtsError = createSelector(getDebtsState, (state: DebtsState) => state.error);

export const getAllDebts = createSelector(getDebtsState, (state: DebtsState) => selectAll(state));

export const getDebtsRequestParams = createSelector(getDebtsState, (state: DebtsState) => state.requestParams);

export const getDebtsTotalCount = createSelector(getDebtsState, (state: DebtsState) => state.totalCount);

export const getDebtsEntities = createSelector(getDebtsState, (state: DebtsState) => selectEntities(state));

export const getSelectedDebtId = createSelector(getDebtsState, (state: DebtsState) => state.selectedId);

export const getSelectedDebt = createSelector(getDebtsEntities, getSelectedDebtId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);
