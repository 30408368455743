import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { clone, DialogService, PanelService } from '@dc/shared';
import { TranslateService } from '@ngx-translate/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  CompaniesResponse,
  Company,
  DEFAULT_REQUEST_PARAMS,
  DeletedEnum,
  PAGE_SIZE_OPTIONS,
  RequestParams,
} from '@dc/data-models';
import { CompaniesService } from '../../services/companies.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'dc-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements AfterViewInit {
  public displayedColumns: string[] = ['name', 'taxId', 'phone', 'email', 'companyForm', 'actions'];
  public dataSource: Company[] = [];
  public totalCount = 0;
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public requestParams: RequestParams = clone(DEFAULT_REQUEST_PARAMS);

  constructor(
    private companiesService: CompaniesService,
    private dialogService: DialogService,
    private panelService: PanelService,
    private translateService: TranslateService,
    private vcf: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  public ngAfterViewInit(): void {
    this.getListData();
  }

  public getListData(): void {
    this.companiesService.getList(this.requestParams).subscribe((res: CompaniesResponse) => {
      this.dataSource = res.companies;
      this.totalCount = res.totalCount;
      this.cd.detectChanges();
    });
  }

  public pageChange(event: any): void {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this.getListData();
  }

  public deletedFilterChanged(event: MatSlideToggleChange): void {
    this.requestParams.page = 0;
    this.requestParams.deleted = event.checked ? DeletedEnum.ALL : DeletedEnum.NOT_DELETED;
    this.getListData();
  }

  public remove(company: Company, event: Event): void {
    event.stopPropagation();
    const dialog = this.dialogService.openConfirmDialog(
      this.translateService.instant('CONFIRM_DIALOG.MSG', { name: company.name + ' (' + company.taxationId + ')' })
    );

    dialog
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.companiesService.remove(company.id);
          } else {
            return of(false);
          }
        })
      )
      .subscribe(() => {
        this.getListData();
      });
  }

  public openSidePanel(templateRef: TemplateRef<any>, company?: Company, event?: Event): void {
    event?.stopPropagation();
    if (company) {
      // this. = administrator;
    }

    const portal = new TemplatePortal(templateRef, this.vcf);
    this.panelService.open(portal);
  }

  public elementClicked(company: Company): void {
    this.router.navigate(['companies', company.id]);
  }
}
