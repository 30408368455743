import { Component, OnInit } from '@angular/core';
import { Company, CompanyResponse, CompanyTypeIcon } from '@dc/data-models';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '../../services/companies.service';
import { ToastService } from '@dc/shared';

@Component({
  selector: 'dc-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  public company!: Company;

  public readonly companyTypeIcon = CompanyTypeIcon;

  constructor(
    private companiesService: CompaniesService,
    private toastService: ToastService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    if (!this.route.snapshot.params['id']) {
      throw new Error('Company id is null');
    }

    this.companiesService.get(this.route.snapshot.params['id']).subscribe((companyResp: CompanyResponse) => {
      this.company = companyResp.company;
    });
  }

  public triggerFileSelectForImport(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  public importDebts(event: Event): void {
    if (!event.target || !(<HTMLInputElement>event.target).files?.length) {
      return;
    }
    const file = (<FileList>(<HTMLInputElement>event.target).files)[0];
    this.companiesService.importDebts(this.company.id, file).subscribe(() => {
      this.toastService.success();
    });
  }
}
