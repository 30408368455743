import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as CollectorMapActions from './collector-map.actions';
import * as CollectorMapFeature from './collector-map.reducer';
import * as CollectorMapSelectors from './collector-map.selectors';

@Injectable()
export class CollectorMapFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(CollectorMapSelectors.getCollectorMapLoaded));
  allCollectorMap$ = this.store.pipe(select(CollectorMapSelectors.getAllCollectorMap));
  selectedCollectorMap$ = this.store.pipe(select(CollectorMapSelectors.getSelectedMapDebt));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(CollectorMapActions.initCollectorMap());
  }
}
