import {
  Address,
  DEFAULT_REQUEST_PARAMS,
  Invoice,
  ListResponseOK,
  Location,
  RequestParams,
  ResponseOK,
  SortDirection,
} from '@dc/data-models';
import { clone } from '@dc/shared';
/**
 * Interface for the 'Debts' data
 */

export interface DebtsEntity {
  id: string;
  address: Address;
  collectorStatus: DebtStatusEnum;
  collectorQueNumber: number;
  queNumber: number;
  createdDate: Date;
  debtorId: string;
  debtorNumber: string;
  email: string;
  invoice: Invoice;
  location: Location;
  name: string;
  phone: string;
  status: string;
}

export enum DebtStatusEnum {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  ACTIVE = 'ACTIVE',
  QUEUED = 'QUEUED',
}

export interface DebtResponse extends ResponseOK {
  debts: DebtsEntity;
}

export interface DebtsResponse extends ListResponseOK {
  debts: DebtsEntity[];
}

export interface DebtsRequestParams extends RequestParams {
  companyId?: string;
  debtorId?: string;
  sort?: string | 'id' | 'createdDate' | 'invoicePaymentDate' | 'companyId';
}

export const DEFAULT_DEBTS_REQUEST_PARAMS = {
  ...clone(DEFAULT_REQUEST_PARAMS),
  sort: 'invoicePaymentDate',
  order: <SortDirection>'asc',
};
