import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as DebtorsActions from './debtors.actions';
import * as DebtorsSelectors from './debtors.selectors';
import { DebtorsRequestParams } from './debtors.models';

@Injectable()
export class DebtorsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  public loaded$ = this.store.pipe(select(DebtorsSelectors.getDebtorsLoaded));
  public allDebtors$ = this.store.pipe(select(DebtorsSelectors.getAllDebtors));
  public totalCount$ = this.store.pipe(select(DebtorsSelectors.getTotalCount));
  public selectedDebtors$ = this.store.pipe(select(DebtorsSelectors.getSelected));
  public requestParams$ = this.store.pipe(select(DebtorsSelectors.getRequestParams));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(DebtorsActions.initDebtors());
  }

  public loadDebtors(requestParams: DebtorsRequestParams): void {
    this.store.dispatch(DebtorsActions.loadDebtors({ requestParams }));
  }
}
