<div class="administrators">

  <div class="section-header">
    <h1>{{'ADMINISTRATORS.ADMINISTRATORS' | translate}}</h1>

    <div class="section-header__topbar">
      <button class="projects-add-btn" mat-flat-button color="accent" type="button" (click)="openSidePanel(addAdministratorTemplateRef)">
        <span class="material-icons">add</span>
        {{'ADMINISTRATORS.ADD' | translate}}
      </button>
      <span class="section-header__spacer"></span>
      <mat-slide-toggle labelPosition="before" (change)="deletedFilterChanged($event)">
        {{'ADMINISTRATORS.SHOW_DELETED' | translate}}
      </mat-slide-toggle>
    </div>
  </div>

  <ng-template #addAdministratorTemplateRef>
    <dc-add-administrator (administratorAdded)="onAdministratorAdded($event)"></dc-add-administrator>
  </ng-template>

  <div class="administrators__table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDisableClear matSortDirection="desc">
      <!-- Full name Column -->
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>{{'ADMINISTRATORS.FULL_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.fullName}}</td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>{{'ADMINISTRATORS.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.username}}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          {{'ADMINISTRATORS.EMAIL' | translate}}
        </th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="dcol-table__action-column">
          <button *ngIf="!row.deleted" type="button" mat-icon-button (click)="remove(row)" matTooltip="{{'DELETE' | translate}}" aria-label="Remove">
            <mat-icon class="dcol-icon-red" fontSet="material-icons-outlined">delete</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="openSidePanel(editAdminTemplateRef, row)" aria-label="Remove"
                  matTooltip="{{'EDIT' | translate}}">
            <mat-icon fontSet="material-icons-outlined" class="dcol-icon-orange">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.deletedRow]="row.deleted"></tr>
    </table>
  </div>

    <mat-paginator
      [length]="totalCount"
      [pageSize]="requestParams.limit"
      [pageIndex]="requestParams.page"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageChange($event)"
    ></mat-paginator>

</div>

<ng-template #editAdminTemplateRef>
  <dc-add-administrator [administrator]="editedAdministrator" (administratorAdded)="onAdministratorAdded($event)"></dc-add-administrator>
</ng-template>
