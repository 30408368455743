<div class="container mx-auto" *ngIf="vm$ | async as vm">
  <mat-card class="login-panel">

    <mat-card-header class="login-panel__header">
      <h1 class="login-panel__name"> {{ 'APP.NAME.'+appName | uppercase | translate }}</h1>
    </mat-card-header>

    <mat-card-content class="login-panel__content">
      <form class="login-form" name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="login()" autocomplete="off">
          <mat-form-field color="primary">
            <mat-label>{{'AUTH.USERNAME' | translate}}</mat-label>
            <input
              matInput
              autocomplete="off"
              id="username"
              name="username"
              placeholder="{{'AUTH.USERNAME' | translate}}"
              formControlName="username"
              required
            />
            <mat-error>{{'AUTH.ERRORS.USERNAME' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'AUTH.PASSWORD' | translate}}</mat-label>
            <input
              matInput
              autocomplete="off"
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              formControlName="password"
              required
            />
            <mat-error>{{'AUTH.ERRORS.PASSWORD' | translate}}</mat-error>
          </mat-form-field>

        <div class="forgot-password mb-8">
          <a class="forgot-password__link" routerLink="/auth/forgot-password">
            * {{ 'AUTH.FORGOT_PASSWORD' | translate}}
          </a>
        </div>

        <button
          mat-flat-button
          color="accent"
          class="submit-button"
          type="submit"
        >
          <span class="submit-button-inner">
          {{ 'AUTH.SIGN_IN' | translate }}
          </span>
        </button>

        <div class="text-center mt-4" *ngIf="appName === 'COLLECTOR'">
          <p>{{ 'AUTH.ARE_NEW_USER' | translate }}<br>
            <a routerLink="/auth/register">{{ 'AUTH.JOIN_NOW' | translate }}</a>
          </p>
        </div>

        <div class="footer mt-8">
          <span>© 2022 Odzyskator sp. z o.o. <strong>v{{version}}</strong></span>
          <span>powered by <a href="https://maiosoftwarehouse.com" target="_blank" rel="noopener" rel="nofollow" rel="noreferrer">Maio Software House</a></span>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
