import { createAction, props } from '@ngrx/store';
import { ActivateInput, Authenticate, RegisterInput, Session } from '@dc/data-models';
import { CompanyInfo, Profile } from './auth.models';

export const authLoginStart = createAction('[Auth] Login start', props<{ authenticate: Authenticate }>());
export const authSuccess = createAction('[Auth/API] Login Success', props<{ session: Session; redirect: boolean }>());
export const authFailure = createAction('[Auth/API] Login Failure', props<{ error: any }>());

export const authLoadUser = createAction('[Auth] Load user');
export const authLoadUserSuccess = createAction('[Auth/API] Load user success', props<{ user: Profile }>());
export const authLoadUserFailure = createAction('[Auth/API] Load user error', props<{ error: any }>());

export const authSignupStart = createAction('[Auth] Signup start');
export const authClearError = createAction('[Auth] Clear error');

export const authAutoLogin = createAction('[Auth] Auto login start');
export const authAutoLoginSuccess = createAction(
  '[Auth] Auto login success',
  props<{ session: Session; loaded: boolean; redirect: boolean }>()
);
export const authAutoLoginFailure = createAction('[Auth] Auto login failure', props<{ error: any }>());

export const authLogout = createAction('[Auth] Logout');

export const authRegisterInit = createAction('[Auth Register Page] Auth Register Page Init');
export const authRegister = createAction(
  '[Auth Register Page] Auth Register start',
  props<{ registerInput: RegisterInput }>()
);
export const authRegisterSuccess = createAction('[Auth Register Page] Auth Register Success');
export const authRegisterFailure = createAction('[Auth Register Page] Auth Register Failure', props<{ error: any }>());

export const authActivateInit = createAction(
  '[Auth Activate Page] Auth Activate Page Init',
  props<{ token: string }>()
);
export const authActivateInitSuccess = createAction(
  '[Auth Activate Page] Auth Activate Page Init Success',
  props<{ user: Profile }>()
);
export const authActivateInitError = createAction(
  '[Auth Activate Page] Auth Activate Page Init Error',
  props<{ error: any }>()
);

export const authActivateGetCompany = createAction(
  '[Auth Activate Page] Auth Activate Get Company Info',
  props<{ token: string; taxId: string }>()
);
export const authActivateGetCompanySuccess = createAction(
  '[Auth Activate Page] Auth Activate Get Company Info Success',
  props<{ company: CompanyInfo }>()
);
export const authActivateGetCompanyFailure = createAction(
  '[Auth Activate Page] Auth Activate Get Company Info Failure',
  props<{ error: any }>()
);

export const authActivate = createAction(
  '[Auth Activate Page] Auth Activate Start',
  props<{ activateInput: ActivateInput }>()
);
export const authActivateSuccess = createAction('[Auth Activate Page] Auth Activate Success');
export const authActivateFailure = createAction('[Auth Activate Page] Auth Activate Failure', props<{ error: any }>());
