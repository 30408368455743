import { ModuleWithProviders, NgModule } from '@angular/core';
import { authRoutes } from './auth.routes';
import { SharedModule } from '@dc/shared';
import { AUTH_MODULE_CONFIG, AuthModuleConfig, DataModelsModule } from '@dc/data-models';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import { RegisterComponent } from './components/register/register.component';
import { ActivateComponent } from './components/activate/activate.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { LoginEncouragementComponent } from './components/login-encouragement/login-encouragement.component';

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes),
    SharedModule,
    DataModelsModule,
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ActivateComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    ProfileInfoComponent,
    LoginEncouragementComponent,
  ],
  exports: [ProfileInfoComponent, LoginEncouragementComponent],
})
export class AuthModule {
  public static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_MODULE_CONFIG,
          useValue: config,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true,
        },
        AuthFacade,
      ],
    };
  }
}
