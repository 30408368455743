import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { clone, DialogService } from '@dc/shared';
import { TranslateService } from '@ngx-translate/core';
import { PAGE_SIZE_OPTIONS } from '@dc/data-models';
import { PageEvent } from '@angular/material/paginator';
import { DebtorsFacade, DebtorsRequestParams } from '@dc/debtors';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'dc-debtors',
  templateUrl: './debtors.component.html',
  styleUrls: ['./debtors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebtorsComponent implements OnInit {
  public displayedColumns: string[] = ['name', 'createdDate', 'email', 'phone'];

  public totalCount$ = this.debtorsFacade.totalCount$;
  public requestParams$ = this.debtorsFacade.requestParams$;
  public allDebtors$ = this.debtorsFacade.allDebtors$;
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private debtorsFacade: DebtorsFacade
  ) {}

  public ngOnInit(): void {
    this.debtorsFacade.init();
  }

  public onPageChange(event: PageEvent, requestParams: DebtorsRequestParams): void {
    this.debtorsFacade.loadDebtors({
      ...requestParams,
      page: event.pageIndex,
      limit: event.pageSize,
    });
  }

  public onSortChange(event: Sort, requestParams: DebtorsRequestParams): void {
    this.debtorsFacade.loadDebtors({
      ...requestParams,
      sort: event.active,
      order: event.direction,
    });
  }
}
