import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COLLECTOR_MAP_FEATURE_KEY, CollectorMapState, collectorMapAdapter } from './collector-map.reducer';

// Lookup the 'CollectorMap' feature state managed by NgRx
export const getCollectorMapState = createFeatureSelector<CollectorMapState>(COLLECTOR_MAP_FEATURE_KEY);

const { selectAll, selectEntities } = collectorMapAdapter.getSelectors();

export const getCollectorMapLoaded = createSelector(getCollectorMapState, (state: CollectorMapState) => state.loaded);

export const getCollectorMapError = createSelector(getCollectorMapState, (state: CollectorMapState) => state.error);

export const getAllCollectorMap = createSelector(getCollectorMapState, (state: CollectorMapState) => selectAll(state));

export const getCollectorMapEntities = createSelector(getCollectorMapState, (state: CollectorMapState) =>
  selectEntities(state)
);

export const getSelectedMapDebtId = createSelector(
  getCollectorMapState,
  (state: CollectorMapState) => state.selectedId
);

export const getSelectedMapDebt = createSelector(
  getCollectorMapEntities,
  getSelectedMapDebtId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
