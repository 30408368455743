import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from './auth.selectors';
import {
  authActivate,
  authActivateGetCompany,
  authActivateInit,
  authAutoLogin,
  authLoginStart,
  authLogout,
  authRegister,
  authRegisterInit,
} from './auth.actions';
import { ActivateInput, Authenticate, RegisterInput } from '@dc/data-models';
import { map } from 'rxjs';

@Injectable()
export class AuthFacade {
  public loaded$ = this.store.pipe(select(AuthSelectors.getAuthLoaded));
  public user$ = this.store.pipe(select(AuthSelectors.getAuthUser));
  public company$ = this.store.pipe(select(AuthSelectors.getAuthCompany));
  public session$ = this.store.pipe(select(AuthSelectors.getAuthSession));
  public error$ = this.store.pipe(select(AuthSelectors.getAuthError));

  public isAuthenticated$ = this.store.pipe(
    select(AuthSelectors.getAuthSession),
    map((session) => {
      return !!session;
    })
  );

  public isRegisterEmailSent$ = this.store.pipe(select(AuthSelectors.isRegisterEmailSent));

  public accountActivateStatus$ = this.store.pipe(select(AuthSelectors.accountActivateStatus));

  constructor(private readonly store: Store) {}

  public login(authenticate: Authenticate): void {
    this.store.dispatch(authLoginStart({ authenticate }));
  }

  public logout(): void {
    this.store.dispatch(authLogout());
  }

  public autoLogin(): void {
    this.store.dispatch(authAutoLogin());
  }

  public registerPageInit(): void {
    this.store.dispatch(authRegisterInit());
  }

  public register(registerInput: RegisterInput): void {
    this.store.dispatch(authRegister({ registerInput }));
  }

  public initActivationPage(token: string): void {
    this.store.dispatch(authActivateInit({ token }));
  }

  public activate(activateInput: ActivateInput): void {
    this.store.dispatch(authActivate({ activateInput }));
  }

  public getCompany(token: string, taxId: string): void {
    this.store.dispatch(authActivateGetCompany({ token, taxId }));
  }
}
