<dc-toast>
  <p>
    {{ 'AUTH.LOGIN_TO_ENABLE' | translate}}
  </p>
  <p>
    <a routerLink="/auth/login" mat-flat-button>
      <mat-icon fontSet="material-icons-outlined">login</mat-icon>
      {{ 'AUTH.SIGN_IN' | translate }}
    </a>
  </p>
</dc-toast>
