import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministratorsComponent } from './components/administrators/administrators.component';
import { AddAdministratorComponent } from './components/add-administrator/add-administrator.component';
import { SharedModule } from '@dc/shared';


@NgModule({
  declarations: [
    AdministratorsComponent,
    AddAdministratorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class AdministratorsModule { }
