import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDebts from './+state/debts/debts.reducer';
import { DebtsEffects } from './+state/debts/debts.effects';
import { DebtsFacade } from './+state/debts/debts.facade';
import { Environment, ENVIRONMENT } from '@dc/data-models';
import * as fromCollectorMap from './+state/collector-map/collector-map.reducer';
import { CollectorMapEffects } from './+state/collector-map/collector-map.effects';
import { CollectorMapFacade } from './+state/collector-map/collector-map.facade';
import * as fromCollectorDebts from './+state/collector-debts/collector-debts.reducer';
import { CollectorDebtsEffects } from './+state/collector-debts/collector-debts.effects';
import { CollectorDebtsFacade } from './+state/collector-debts/collector-debts.facade';
import { DebtQueueComponent } from './components/debt-queue/debt-queue.component';
import { SharedModule } from '@dc/shared';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(fromDebts.DEBTS_FEATURE_KEY, fromDebts.debtsReducer),
    EffectsModule.forFeature([DebtsEffects]),
    StoreModule.forFeature(fromCollectorMap.COLLECTOR_MAP_FEATURE_KEY, fromCollectorMap.collectorMapReducer),
    EffectsModule.forFeature([CollectorMapEffects]),
    StoreModule.forFeature(fromCollectorDebts.COLLECTOR_DEBTS_FEATURE_KEY, fromCollectorDebts.collectorDebtsReducer),
    EffectsModule.forFeature([CollectorDebtsEffects]),
  ],
  declarations: [DebtQueueComponent],
  providers: [DebtsFacade, CollectorMapFacade, CollectorDebtsFacade],
  exports: [DebtQueueComponent],
})
export class DebtsModule {
  public static forRoot(environment: Environment): ModuleWithProviders<DebtsModule> {
    return {
      ngModule: DebtsModule,
      providers: [
        {
          provide: ENVIRONMENT,
          useValue: environment,
        },
      ],
    };
  }
}
