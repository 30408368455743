import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DEFAULT_REQUEST_PARAMS,
  ImportDebtsResponse,
  ImportFile,
  ImportFileStatusEnum,
  PAGE_SIZE_OPTIONS,
  RequestParams,
} from '@dc/data-models';
import { CompaniesService } from '../../services/companies.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { clone } from '@dc/shared';
import { PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'dc-import-debts',
  templateUrl: './import-debts.component.html',
  styleUrls: ['./import-debts.component.scss'],
})
export class ImportDebtsComponent implements OnInit, AfterViewInit, OnDestroy {
  public dataSource: ImportFile[] = [];
  public totalCount = 0;
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public requestParams: RequestParams = clone(DEFAULT_REQUEST_PARAMS);
  public displayedColumns: string[] = ['filename', 'id', 'status', 'summary', 'errorMessage'];

  private companyId!: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private companiesService: CompaniesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    delete this.requestParams.deleted;
  }

  public ngOnInit(): void {
    if (!(<ActivatedRouteSnapshot>this.route.snapshot.parent).params['id']) {
      throw new Error('Company id is null');
    }

    this.companyId = (<ActivatedRouteSnapshot>this.route.snapshot.parent).params['id'];
    this.companiesService
      .importFileListChanged()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.getListData());
  }

  public ngAfterViewInit(): void {
    this.getListData();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getListData(): void {
    this.companiesService.getImportDebts(this.companyId, this.requestParams).subscribe((res: ImportDebtsResponse) => {
      this.dataSource = res.importFiles;
      this.totalCount = res.totalCount;
      this.cd.detectChanges();
    });
  }

  public pageChange(event: PageEvent): void {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this.getListData();
  }
}
