import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as DebtorsActions from './debtors.actions';
import { DebtorsEntity, DebtorsRequestParams } from './debtors.models';
import { clone } from '@dc/shared';
import { DEFAULT_REQUEST_PARAMS, RequestParams } from '@dc/data-models';

export const DATA_DEBTORS_FEATURE_KEY = 'debtors';

export interface DebtorsState extends EntityState<DebtorsEntity> {
  selectedId?: string | number; // which Debtors record has been selected
  requestParams: DebtorsRequestParams;
  totalCount: number;
  loaded: boolean; // has the Debtors list been loaded
  error?: string | null; // last known error (if any)
}

export interface DebtorsPartialState {
  readonly [DATA_DEBTORS_FEATURE_KEY]: DebtorsState;
}

export const debtorsAdapter: EntityAdapter<DebtorsEntity> = createEntityAdapter<DebtorsEntity>();

export const initialDebtorsState: DebtorsState = debtorsAdapter.getInitialState({
  // set initial required properties
  requestParams: { ...clone(DEFAULT_REQUEST_PARAMS), sort: 'name', order: 'asc' },
  totalCount: 0,
  loaded: false,
});

const reducer = createReducer(
  initialDebtorsState,
  on(DebtorsActions.initDebtors, (state) => ({ ...state, loaded: false, error: null })),
  on(DebtorsActions.loadDebtors, (state, payload) => {
    return { ...state, requestParams: payload.requestParams, loaded: false, error: null };
  }),
  on(DebtorsActions.loadDebtorsSuccess, (state, { debtors, totalCount }) =>
    debtorsAdapter.setAll(debtors, { ...state, loaded: true, totalCount })
  ),
  on(DebtorsActions.loadDebtorsFailure, (state, { error }) => ({ ...state, error }))
);

export function debtorsReducer(state: DebtorsState | undefined, action: Action) {
  return reducer(state, action);
}
