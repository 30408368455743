import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Administrator, AdministratorResponse, AdministratorsResponse, RequestParams } from '@dc/data-models';

@Injectable({
  providedIn: 'root',
})
export class AdministratorsService {
  constructor(private http: HttpClient) {}

  public getList(params: RequestParams): Observable<AdministratorsResponse> {
    return this.http.get<AdministratorsResponse>(environment.apiUrl + '/accounts', { params });
  }

  public get(id: string): Observable<AdministratorResponse> {
    return this.http.get<AdministratorResponse>(environment.apiUrl + '/accounts/' + id);
  }

  public remove(id: string): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/accounts/' + id);
  }

  public create(administratorData: Administrator): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/accounts', administratorData);
  }

  public update(administratorId: string, administratorData: Administrator): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/accounts/' + administratorId, administratorData);
  }
}
