import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewContainerRef } from '@angular/core';
import { AdministratorsService } from '../../services/administrators.service';
import { clone, DialogService, PanelService } from '@dc/shared';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Administrator,
  AdministratorsResponse,
  DEFAULT_REQUEST_PARAMS,
  DeletedEnum,
  PAGE_SIZE_OPTIONS,
  RequestParams,
} from '@dc/data-models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dc-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss'],
})
export class AdministratorsComponent implements AfterViewInit {
  public displayedColumns: string[] = ['fullName', 'username', 'email', 'actions'];

  public editedAdministratorId!: string;
  public editedAdministrator!: Administrator;

  public dataSource: Administrator[] = [];
  public totalCount = 0;
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public requestParams: RequestParams = clone(DEFAULT_REQUEST_PARAMS);

  constructor(
    private administratorService: AdministratorsService,
    private dialogService: DialogService,
    private panelService: PanelService,
    private translateService: TranslateService,
    private vcf: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.getListData();
  }

  public getListData(): void {
    this.administratorService.getList(this.requestParams).subscribe((res: AdministratorsResponse) => {
      this.dataSource = res.users;
      this.totalCount = res.totalCount;
      this.cd.detectChanges();
    });
  }

  public pageChange(event: any): void {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this.getListData();
  }

  public deletedFilterChanged(event: MatSlideToggleChange): void {
    this.requestParams.page = 0;
    this.requestParams.deleted = event.checked ? DeletedEnum.ALL : DeletedEnum.NOT_DELETED;
    this.getListData();
  }

  public remove(admin: Administrator): void {
    const dialog = this.dialogService.openConfirmDialog(
      this.translateService.instant('CONFIRM_DIALOG.MSG', { name: admin.fullName })
    );

    dialog
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.administratorService.remove(admin.id);
          } else {
            return of(false);
          }
        })
      )
      .subscribe(() => {
        this.getListData();
      });
  }

  public openSidePanel(templateRef: TemplateRef<any>, administrator?: Administrator): void {
    if (administrator) {
      this.editedAdministrator = administrator;
    }

    const portal = new TemplatePortal(templateRef, this.vcf);
    this.panelService.open(portal);
  }

  public onAdministratorAdded(administrator: Administrator): void {
    this.getListData();
  }
}
