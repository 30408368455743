import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserResponse, RequestParams, UsersResponse } from '@dc/data-models';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  public getList(params: RequestParams): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(environment.apiUrl + '/users', { params });
  }

  public remove(id: string): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/users/' + id);
  }

  public get(id: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(environment.apiUrl + '/users/' + id);
  }

  public create(userData: User): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/users', userData);
  }

  public update(userId: string, userData: User): Observable<void> {
    return this.http.put<void>(environment.apiUrl + '/users/' + userId, userData);
  }
}
