import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as DebtsActions from './debts.actions';
import * as DebtsSelectors from './debts.selectors';
import { DebtsRequestParams } from './debts.models';

@Injectable()
export class DebtsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  public loaded$ = this.store.pipe(select(DebtsSelectors.getDebtsLoaded));
  public allDebts$ = this.store.pipe(select(DebtsSelectors.getAllDebts));
  public totalCount$ = this.store.pipe(select(DebtsSelectors.getDebtsTotalCount));
  public selectedDebt$ = this.store.pipe(select(DebtsSelectors.getSelectedDebt));
  public requestParams$ = this.store.pipe(select(DebtsSelectors.getDebtsRequestParams));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(DebtsActions.initDebts());
  }

  public initPublic(): void {
    this.store.dispatch(DebtsActions.initDebtsPublic());
  }

  public loadDebts(requestParams: DebtsRequestParams): void {
    this.store.dispatch(DebtsActions.loadDebts({ requestParams }));
  }

  public selectDebt(debtId: string): void {
    this.store.dispatch(DebtsActions.selectDebt({ debtId }));
  }

  // public loadDebt(debtId: string): void {
  //   this.store.dispatch(DebtsActions.loadDebt({ debtId }));
  // }

  public assignDebt(debtId: string): void {
    this.store.dispatch(DebtsActions.assignDebt({ debtId }));
  }
}
