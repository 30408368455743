import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';


@Injectable({ providedIn: 'root' })
export class DialogService {
  private confirmDialogConfig = {
    width: '550px',
    disableClose: true,
  };

  constructor(private dialog: MatDialog) {}

  public openConfirmDialog(
    question: string
  ): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      ...this.confirmDialogConfig,
      data: {
        question: question,
      },
    });
  }
}
