import { DEFAULT_REQUEST_PARAMS, ListResponseOK, RequestParams, SortDirection } from '@dc/data-models';
import { DebtsEntity } from '../debts/debts.models';
import { clone } from '@dc/shared';

/**
 * Interface for the 'CollectorDebts' data
 */
export interface CollectorDebtsEntity {
  createdDate: Date;
  debt: DebtsEntity;
  id: string; // Primary ID
  status: CollectorDebtStatusEnum;
  validTill: Date;
}

export enum CollectorDebtStatusEnum {
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  ACTIVE = 'ACTIVE',
  QUEUED = 'QUEUED',
}

export interface CollectorDebtsResponse extends ListResponseOK {
  debts: CollectorDebtsEntity[];
}

export interface CollectorDebtsRequestParams extends RequestParams {}

export const DEFAULT_COLLECTOR_DEBTS_REQUEST_PARAMS = {
  ...clone(DEFAULT_REQUEST_PARAMS),
};
