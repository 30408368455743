<div class="import-debts-status">

  <div class="import-debts-status__item-wrapper" *ngFor="let processItem of processList; let last = last">
    <div class="import-debts-status__item">
      <div class="import-debts-status__item-circle" [class.import-debts-status__item-circle-completed]="processItem.completed">
        <mat-icon *ngIf="processItem.completed">check_circle</mat-icon>
        <mat-icon *ngIf="!processItem.completed">radio_button_unchecked</mat-icon>
      </div>
      <div class="import-debts-status__item-label">
        {{ 'DEBTS.IMPORT_FILE_STATUS_SHORT_' + processItem.status | translate }}
      </div>
    </div>
    <div class="import-debts-status__item-line" *ngIf="!last"></div>
  </div>

</div>

