import { Address, BankAccount, ShareCapital } from '../common/shared.model';
import { ListResponseOK, ResponseOK } from '../common/response.model';
import { UserTypeEnum } from '../users/users.model';

export interface Company {
  id: string;
  name: string;
  taxationId: string;
  companyForm: {
    formType: BusinessActivityTypeEnum;
    ncrNumber?: string | null;
    ncr?: NCR | null;
    totalShares?: number | null;
    capital?: number | null;
    fullyPaidCapital?: boolean | null;
  };
  nrcNo?: string; //numenr KRS
  nrc?: NationalRegisterCourt; // Sad KRS
  pcaList?: string[]; // PKD
  address: Address;
  phone: string;
  email: string;
  bankAccount: BankAccount;
  isTaxPayer?: boolean;
  shareCapital?: ShareCapital;
  deleted: boolean;
  companyType: CompanyTypeEnum[];
  updatedDate?: Date;
  createdDate?: Date;
}

export interface CompanyResponse extends ResponseOK {
  company: Company;
}

export interface CompaniesResponse extends ListResponseOK {
  companies: Company[];
}

export interface ImportFile {
  id: string;
  filename: string;
  status: ImportFileStatusEnum;
  errorMessage: string;
  summary: ImportFileSummary | null;
  companyId: string;
  createdById: string;
}

export interface ImportDebtsResponse extends ListResponseOK {
  importFiles: ImportFile[];
}

export interface ImportFileSummary {
  errors: unknown;
  insertedRows: number;
  totalRows: number;
  updatedRows: number;
}

export interface ImportFileResponse extends ResponseOK {
  importFile: ImportFile;
}

export enum ImportFileStatusEnum {
  TO_IMPORT = 'TO_IMPORT',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

// @TODO confirm if this model is deprecated
export interface NationalRegisterCourt {
  id: string;
  city: string;
  name: string;
  address: Address;
}

export enum BusinessActivityTypeEnum {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP', // Działalność jednoosobowa , No KRS
  PARTNERSHIP = 'PARTNERSHIP', // Spółka Cywilna, No KRS
  LIMITED_LIABILITY_COMPANY = 'LIMITED_LIABILITY_COMPANY', //Spólka Zoo
  JOINT_STOCK_COMPANY = 'JOINT_STOCK_COMPANY', // Spółka Akcyjna
  GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP', // Spółka Jawna
  A_PARTNERSHIP = 'A_PARTNERSHIP', // Spółka Partnerska
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP', // Spólka komandytowa
  PARTNERSHIP_LIMITED_BY_SHARES = 'PARTNERSHIP_LIMITED_BY_SHARES', // Spólka komandytowa - akcyjna
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP', // Spólka komandytowa - zoo
}

export enum CompanyTypeEnum {
  CREDITOR = 'CREDITOR',
  COLLECTOR = 'COLLECTOR',
  OTHER = 'OTHER',
}

export const CompanyTypeIcon: { [key: string]: string } = {
  [CompanyTypeEnum.COLLECTOR]: 'savings',
  [CompanyTypeEnum.CREDITOR]: 'psychology',
  [CompanyTypeEnum.OTHER]: 'extension',
};

export interface PKD {
  code: string;
  desc: string;
}

export interface NCR {
  court: string;
  address: string;
  departments: string;
}
