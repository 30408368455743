import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { catchError, EMPTY, exhaustMap, map, Observable, of, tap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { Authenticate, COOKIE_CONFIG, Session, SessionResponse } from '@dc/data-models';
import { Action } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ToastService } from '@dc/shared';
import { ProfileResponse } from './auth.models';

@Injectable()
export class AuthEffects {
  types = AuthActions;
  init$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authLoginStart),
      exhaustMap((state) => {
        return this.authService.login(<Authenticate>state.authenticate).pipe(
          map((sessionResponse: SessionResponse) => {
            return AuthActions.authSuccess({ session: sessionResponse.session, redirect: true });
          }),
          catchError((err) => {
            return of(AuthActions.authFailure({ error: err.error?.code ? err.error.code : err.error }));
          })
        );
      })
    );
  });

  autoLogin$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authAutoLogin),
      map(() => {
        if (this.authService.checkCookieToken(COOKIE_CONFIG.authCookieName)) {
          const session: Session = JSON.parse(this.cookieService.get(COOKIE_CONFIG.authCookieName));
          return AuthActions.authAutoLoginSuccess({ session, redirect: false, loaded: true });
        } else {
          return AuthActions.authAutoLoginFailure({ error: 'AUTH.ERRORS.NO_SESSION_COOKIE' });
        }
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authSuccess, AuthActions.authAutoLoginSuccess),
      tap((state) => {
        if (state.redirect) {
          const redirectUrl = state.redirect;
          this.router.navigate(['/']).then();
        }
      }),
      map(() => AuthActions.authLoadUser())
    );
  });

  loginError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authFailure),
      tap((state) => {
        this.toastService.error(`AUTH.ERRORS.CODE_${state.error}`);
      }),
      exhaustMap(() => EMPTY)
    );
  });

  loadUser$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authLoadUser),
      exhaustMap(() => {
        return this.authService.getUser().pipe(
          map((profileResponse: ProfileResponse) => {
            return AuthActions.authLoadUserSuccess({ user: profileResponse.profile });
          }),
          catchError((err) => {
            return of(AuthActions.authLoadUserFailure({ error: err.error }));
          })
        );
      })
    );
  });

  loadUserError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authLoadUserFailure),
      tap(() => {
        this.toastService.error('AUTH.ERRORS.GET_PROFILE_ERROR');
      }),
      exhaustMap(() => EMPTY)
    );
  });

  register$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authRegister),
      exhaustMap((state) => {
        return this.authService.register(state.registerInput).pipe(
          map(() => {
            return AuthActions.authRegisterSuccess();
          }),
          catchError((err) => {
            return of(AuthActions.authRegisterFailure({ error: err.error.code }));
          })
        );
      })
    );
  });

  registerError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authRegisterFailure),
      tap((state) => {
        this.toastService.error('AUTH.ERRORS.' + (state.error ? state.error : 'REGISTER_FAILED'));
      }),
      exhaustMap(() => EMPTY)
    );
  });

  activateInit$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateInit),
      exhaustMap((state) => {
        return this.authService.getInactiveAccount(state.token).pipe(
          map((resp) => {
            return AuthActions.authActivateInitSuccess({ user: resp.user });
          }),
          catchError((err) => {
            return of(AuthActions.authActivateInitError({ error: err.error.code }));
          })
        );
      })
    );
  });

  activateInitError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateInitError),
      tap(() => {
        this.toastService.error('AUTH.ERRORS.GET_INACTIVE_ACCOUNT');
        this.router.navigate(['/auth/activate']).then();
      }),
      exhaustMap(() => EMPTY)
    );
  });

  activate$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivate),
      exhaustMap((state) => {
        return this.authService.activate(state.activateInput).pipe(
          map(() => {
            return AuthActions.authActivateSuccess();
          }),
          catchError((err) => {
            return of(AuthActions.authActivateFailure({ error: err.error }));
          })
        );
      })
    );
  });

  activateSuccess$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateSuccess),
      tap(() => {
        this.toastService.success('AUTH.MESSAGES.ACTIVATION_SUCCESS');
      }),
      exhaustMap(() => EMPTY)
    );
  });

  activateError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateFailure),
      tap(() => {
        this.toastService.error('AUTH.ERRORS.ACTIVATION_FAILED');
      }),
      exhaustMap(() => EMPTY)
    );
  });

  activateGetCompany$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateGetCompany),
      exhaustMap((state) => {
        return this.authService.getCompanyRegistrationInfo(state.token, state.taxId).pipe(
          map((companyResponse) => {
            return AuthActions.authActivateGetCompanySuccess({ company: companyResponse.company });
          }),
          catchError((err) => {
            return of(AuthActions.authActivateGetCompanyFailure({ error: err.error.code }));
          })
        );
      })
    );
  });

  activateGetCompanyError$ = createEffect(() => {
    return <Observable<Action>>this.actions$.pipe(
      ofType(AuthActions.authActivateGetCompanyFailure),
      tap((state) => {
        this.toastService.error('AUTH.ERRORS.' + state.error);
      }),
      exhaustMap(() => EMPTY)
    );
  });

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private toastService: ToastService
  ) {}
}
