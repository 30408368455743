export declare type SortDirection = 'asc' | 'desc' | '';

export enum DeletedEnum {
  NOT_DELETED = 'NOT_DELETED',
  DELETED = 'DELETED',
  ALL = 'ALL',
}

export interface RequestParams {
  page?: number;
  limit?: number;
  sort?: string;
  order?: SortDirection;
  deleted?: DeletedEnum;
  [param: string]: any;
}

export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];
export const DEFAULT_REQUEST_PARAMS: RequestParams = {
  page: 0,
  limit: 25,
};
