import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

export interface ToastConfig {
  closeButton?: boolean;
  disableTimeout?: boolean;
  enableHtml?: boolean;
}

const DEFAULT_TOAST_CONFIG: ToastConfig = {
  closeButton: false,
  disableTimeout: false,
  enableHtml: false,
};

@Injectable()
export class ToastService {
  constructor(private toast: ToastrService, private translate: TranslateService) {}

  public error(translationKey: string = 'MESSAGES.ERROR.GENERAL'): void {
    // console.log(translationKey);
    this.toast.error(this.translate.instant(translationKey));
  }

  public success(
    translationKey: string = 'MESSAGES.SUCCESS.GENERAL',
    config: ToastConfig = DEFAULT_TOAST_CONFIG
  ): void {
    this.toast.success(this.translate.instant(translationKey), undefined, {
      closeButton: config.closeButton,
      disableTimeOut: config.disableTimeout,
    });
  }

  public info(
    translationKey: string = 'MESSAGES.SUCCESS.GENERAL',
    config: ToastConfig = DEFAULT_TOAST_CONFIG
  ): ActiveToast<unknown> {
    const msg = this.translate.instant(translationKey);

    return this.toast.info(msg, undefined, {
      closeButton: config.closeButton,
      disableTimeOut: config.disableTimeout,
    });
  }

  public warn(translationKey: string = 'MESSAGES.WARN.GENERAL'): void {
    this.toast.warning(this.translate.instant(translationKey));
  }

  public clearAll(): void {
    this.toast.clear();
  }
}
