import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { Session } from '@dc/data-models';
import { ActivateStatusEnum, CompanyInfo, Profile } from './auth.models';
import { authActivateGetCompanySuccess } from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  user: Profile | null; // which Auth record has been selected
  company: CompanyInfo | null;
  session: Session | null;
  loaded: boolean; // has the Auth list been loaded
  error?: string | null; // last known error (if any)
  registrationEmailSent?: boolean;
  activateStatus: ActivateStatusEnum | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialAuthState: AuthState = {
  // set initial required properties
  user: null,
  company: null,
  session: null,
  loaded: false,
  registrationEmailSent: false,
  activateStatus: null,
};

const reducer = createReducer(
  initialAuthState,
  on(AuthActions.authLoginStart, (state) => ({ ...state, loaded: false, error: null })),
  on(AuthActions.authSuccess, (state, { session }) => ({ ...state, session, loaded: true })),
  on(AuthActions.authFailure, (state, { error }) => ({ ...state, session: null, error, loaded: true })),

  on(AuthActions.authAutoLogin, (state) => ({ ...state, loaded: false, error: null })),
  on(AuthActions.authAutoLoginSuccess, (state, { session }) => ({ ...state, session, loaded: true })),
  on(AuthActions.authAutoLoginFailure, (state, { error }) => ({ ...state, session: null, error, loaded: true })),

  on(AuthActions.authLoadUserSuccess, (state, { user }) => ({ ...state, user, error: null })),
  on(AuthActions.authLoadUserFailure, (state, { error }) => ({ ...state, error, user: null })),

  on(AuthActions.authRegisterInit, (state) => ({ ...state, registrationEmailSent: false, error: null })),
  on(AuthActions.authRegister, (state) => ({ ...state, registrationEmailSent: false, error: null })),
  on(AuthActions.authRegisterSuccess, (state) => ({ ...state, registrationEmailSent: true })),
  on(AuthActions.authRegisterFailure, (state, { error }) => ({ ...state, registrationEmailSent: false, error })),

  on(AuthActions.authActivateInit, (state) => ({
    ...state,
    error: null,
    activateStatus: null,
  })),
  on(AuthActions.authActivateInitSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    activateStatus: ActivateStatusEnum.INIT,
  })),
  on(AuthActions.authActivateInitError, (state, { error }) => ({
    ...state,
    error,
  })),

  on(AuthActions.authActivateGetCompanySuccess, (state, { company }) => ({ ...state, company, error: null })),

  on(AuthActions.authActivateGetCompanyFailure, (state, { error }) => ({ ...state, company: null, error })),

  on(AuthActions.authActivateSuccess, (state) => ({
    ...state,
    activateStatus: ActivateStatusEnum.ACTIVATE_SUCCESS,
  })),
  on(AuthActions.authActivateFailure, (state, { error }) => ({
    ...state,
    activateStatus: ActivateStatusEnum.ACTIVATE_ERROR,
    error,
  })),

  on(AuthActions.authLogout, (state) => ({ ...state, session: null, user: null, error: null, loaded: false }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
