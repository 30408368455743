import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@dc/data-models';

@Component({
  selector: 'dc-company-address',
  templateUrl: './company-address.component.html',
  styleUrls: ['./company-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyAddressComponent {
  @Input() address!: Address | undefined;

  constructor() {}
}
