import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@dc/data-models';

@Component({
  selector: 'dc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  @Input() showCountry = true;
  @Input() address!: Address | undefined;

  constructor() {}
}
