import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthFacade } from '../../+state/auth.facade';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private authFacade: AuthFacade) {}

  public canActivate(): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.authFacade.session$.pipe(
      map((session) => {
        const isAuth = !!session;
        if (isAuth) {
          return true;
        }
        return true; // this.authService.getLoginUrlTree(window.location.pathname);
      })
    );
  }
}
