import { Routes } from '@angular/router';
import { AuthGuard } from '@dc/auth';
import { AdministratorsComponent } from './modules/administrators/components/administrators/administrators.component';
import { CompaniesComponent } from './modules/companies/components/companies/companies.component';
import { DebtsComponent } from './modules/debts/components/debts/debts.component';
import { UsersComponent } from './modules/users/components/users.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { UserDetailsComponent } from './modules/users/components/user-details/user-details.component';
import { CompanyDetailsComponent } from './modules/companies/components/company-details/company-details.component';
import { ImportDebtsComponent } from './modules/companies/components/import-debts/import-debts.component';
import { ImportDebtsLastComponent } from './modules/companies/components/import-debts-last/import-debts-last.component';
import { DebtorsComponent } from './modules/debtors/components/debtors/debtors.component';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainContainerComponent,
    children: [
      {
        path: 'administrators',
        component: AdministratorsComponent,
      },
      {
        path: 'companies',
        component: CompaniesComponent,
      },
      {
        path: 'companies/:id',
        component: CompanyDetailsComponent,
        children: [
          {
            path: '',
            redirectTo: 'import-debts-last',
            pathMatch: 'full',
          },
          {
            path: 'import-debts',
            component: ImportDebtsComponent,
          },
          {
            path: 'import-debts-last',
            component: ImportDebtsLastComponent,
          },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'users/:id',
        component: UserDetailsComponent,
      },
      {
        path: 'debts',
        component: DebtsComponent,
      },
      {
        path: 'debtors',
        component: DebtorsComponent,
      },
      {
        path: '',
        redirectTo: 'administrators',
        pathMatch: 'full',
      },
      {
        path: '*',
        redirectTo: 'administrators',
        pathMatch: 'full',
      },
    ],
  },
];
