import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatchStringValidator, PanelService, ToastService } from '@dc/shared';
import { AdministratorsService } from '../../services/administrators.service';
import { map, Observable } from 'rxjs';
import { Administrator, AdministratorResponse } from '@dc/data-models';

@Component({
  selector: 'dc-add-administrator',
  templateUrl: './add-administrator.component.html',
  styleUrls: ['./add-administrator.component.scss'],
})
export class AddAdministratorComponent implements OnInit {
  @Input() administrator!: Administrator;
  @Input() administratorId!: string;

  @Output() administratorAdded: EventEmitter<Administrator> = new EventEmitter();

  public addAdministratorForm!: FormGroup;
  // public administrator!: Administrator;

  public fullNameCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  public userNameCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  public emailCtrl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private formBuilder: FormBuilder,
    private administratorsService: AdministratorsService,
    private panelService: PanelService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.createAdministratorForm();
    if (this.administratorId) {
      this.getAdministratorData().subscribe();
    }
    if (this.administrator) {
      this.updateAdminFormData();
    }
  }

  private createAdministratorForm() {
    this.addAdministratorForm = this.formBuilder.group({
      fullName: this.fullNameCtrl,
      username: this.userNameCtrl,
      email: this.emailCtrl,
    });
  }

  private getAdministratorData(): Observable<Administrator> {
    return this.administratorsService.get(this.administratorId).pipe(
      map((resp: AdministratorResponse) => {
        this.administrator = resp.user;
        this.updateAdminFormData();
        return this.administrator;
      })
    );
  }

  private updateAdminFormData(): void {
    this.fullNameCtrl.setValue(this.administrator.fullName);
    this.emailCtrl.setValue(this.administrator.email);
    this.userNameCtrl.setValue(this.administrator.username);
  }

  private createAdministrator(administratorData: Administrator) {
    this.administratorsService.create(administratorData).subscribe(
      () => {
        this.panelService.close().then();
        this.administratorAdded.emit(administratorData);
      },
      (error) => {
        this.toastService.error(`MESSAGES.ERROR.${error.error.code}`);
      }
    );
  }

  public submit(): void {
    if (!this.addAdministratorForm.valid) {
      return;
    }
    const administratorData: Administrator = this.addAdministratorForm.getRawValue();
    if (this.administrator) {
      this.administratorsService.update(this.administrator.id, this.addAdministratorForm.getRawValue()).subscribe();
    } else {
      this.createAdministrator(administratorData);
    }
  }
}
