<dc-header (sidebarToggled)="onSidebarToggled()"></dc-header>

<mat-drawer-container>

  <mat-drawer mode="side" [opened]="sideBarOpen">
    <dc-menu [menuItems]="menuItems">
      <ng-container header>{{'APP.NAME.ADMINISTRATOR' | translate}}</ng-container>
      <ng-container version>{{ version }}</ng-container>
      <ng-container user-info *ngIf="vm$ | async as vm">
        <dc-profile-info [profile]="vm.profile"></dc-profile-info>
      </ng-container>
    </dc-menu>
  </mat-drawer>

  <mat-drawer-content class="panel-content" >
    <router-outlet></router-outlet>
  </mat-drawer-content>
  <mat-drawer position="end" #drawer mode="over" class="panel-drawer" disableClose>
    <ng-template [cdkPortalOutlet]="panelService.panelPortal | async"></ng-template>
    <button class="panel-drawer__close" type="button" mat-icon-button (click)="panelService.close()"><mat-icon>close</mat-icon></button>
  </mat-drawer>
</mat-drawer-container>
