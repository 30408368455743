import { DEFAULT_REQUEST_PARAMS, ListResponseOK, RequestParams, ResponseOK, SortDirection } from '@dc/data-models';
import { clone } from '@dc/shared';

/**
 * Interface for the 'Debtors' data
 */
export interface DebtorsEntity {
  id: string;
  /** @deprecated */
  _id: string;
  name: string;
  createdDate: Date;
  /** @deprecated */
  createdDates: Date;
  debtorNumbers: unknown;
  email: string;
  personIdNumber: unknown;
  phone: string;
  taxIdNumber: string;
  type: unknown;
}

export interface DebtorsRequestParams extends RequestParams {
  name?: string;
  email?: string;
  debtorNumbers?: string;
  personIdNumber?: string;
  taxIdNumber?: string;
  sort?: string | 'id' | 'createdDate' | 'name' | 'debtorNumber' | 'type';
}

export const DEFAULT_DEBTORS_REQUEST_PARAMS = {
  ...clone(DEFAULT_REQUEST_PARAMS),
  sort: 'name',
  order: <SortDirection>'asc',
};

export interface DebtorResponse extends ResponseOK {
  debtors: DebtorsEntity;
}

export interface DebtorsResponse extends ListResponseOK {
  debtors: DebtorsEntity[];
}
