import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CollectorDebtsActions from './collector-debts.actions';
import * as CollectorDebtsSelectors from './collector-debts.selectors';
import { CollectorDebtsRequestParams } from './collector-debts.models';

@Injectable()
export class CollectorDebtsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  public loaded$ = this.store.pipe(select(CollectorDebtsSelectors.getCollectorDebtsLoaded));
  public allDebts$ = this.store.pipe(select(CollectorDebtsSelectors.getAllCollectorDebts));
  public selectedCollectorDebts$ = this.store.pipe(select(CollectorDebtsSelectors.getSelectedCollectorDebt));
  public totalCount$ = this.store.pipe(select(CollectorDebtsSelectors.getCollectorDebtsTotalCount));
  public requestParams$ = this.store.pipe(select(CollectorDebtsSelectors.getCollectorDebtsRequestParams));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(CollectorDebtsActions.initCollectorDebts());
  }

  public loadDebts(requestParams: CollectorDebtsRequestParams): void {
    this.store.dispatch(CollectorDebtsActions.loadCollectorDebts({ requestParams }));
  }

  public selectDebt(debtId: string): void {
    this.store.dispatch(CollectorDebtsActions.selectCollectorDebt({ debtId }));
  }

  public removeAssignment(debtId: string): void {
    this.store.dispatch(CollectorDebtsActions.removeAssignment({ debtId }));
  }

  public acceptAssignment(debtId: string): void {
    this.store.dispatch(CollectorDebtsActions.acceptAssignment({ debtId }));
  }
}
