<div class="users">
  <div class="section-header">
    <h1>{{'USERS.USERS' | translate}}</h1>

    <div class="section-header__topbar">
      <button mat-flat-button color="accent" type="button" (click)="openSidePanel(addUserTemplateRef)">
        <span class="material-icons">add</span>
        {{'USERS.ADD_USER' | translate}}
      </button>

      <div class="users__filters">
        <mat-slide-toggle labelPosition="before" (change)="deletedFilterChanged($event)"
                          class="users__list-slide-toggle">
          {{'COMPANIES.SHOW_DELETED' | translate}}
        </mat-slide-toggle>

        <mat-form-field class="users__list-select" appearance="outline">
          <mat-label>{{'USERS.LIST.TYPE' | translate}}</mat-label>
          <mat-select [(value)]="usersRequestParams.userType" (selectionChange)="getListData()">
            <mat-select-trigger>
              <div class="users__type-select-trigger">
                {{ 'USERS.USER_TYPES.' + usersRequestParams.userType | translate }}
              </div>
            </mat-select-trigger>
            <mat-option>---</mat-option>
            <mat-option *ngFor="let type of userTypes" [value]="type.value">
              <mat-icon fontSet="material-icons-outlined">{{type.icon}}</mat-icon>
              {{ 'USERS.USER_TYPES.' + type.value | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </div>

  <ng-template #addUserTemplateRef>
    <dc-add-user (userAdded)="onUserAdded()"></dc-add-user>
  </ng-template>

  <ng-template #editUserTemplateRef>
    <dc-add-user (userAdded)="onUserAdded()" [userId]="user.id"></dc-add-user>
  </ng-template>

  <div class="users__table-container">

    <table mat-table [dataSource]="dataSource">
      <!-- Fullname Column -->
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>{{'USERS.LIST.FULL_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.name}} {{row.surname}}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{'USERS.LIST.TYPE' | translate}}</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon fontSet="material-icons-outlined" matTooltip="{{ 'USERS.USER_TYPES.' + row.userType | translate }}">
            {{ userTypeIcon['' + row.userType] }}
          </mat-icon>
        </td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>{{'USERS.LIST.USERNAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.username}}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'USERS.LIST.EMAIL' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.PHONE' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.phone || '-'}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="dcol-table__action-column">
          <button *ngIf="!row.deleted" type="button" mat-icon-button (click)="remove(row, $event)"
                  matTooltip="{{'DELETE' | translate}}" aria-label="Remove">
            <mat-icon class="dcol-icon-red" fontSet="material-icons-outlined">delete</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="openSidePanel(editUserTemplateRef, row, $event)"
                  aria-label="Remove"
                  matTooltip="{{'EDIT' | translate}}">
            <mat-icon fontSet="material-icons-outlined" class="dcol-icon-orange">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="dcol-table__row-clickable" mat-row *matRowDef="let row; columns: displayedColumns;"
          [class.deletedRow]="row.deleted" (click)="elementClicked(row)"></tr>

    </table>
  </div>

</div>
