import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Amount, CurrencyEnum } from '@dc/data-models';
import { HYPHEN } from '../helpers/utils';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(amount: Amount, format?: string): string {
    if (this.isEmpty(amount)) {
      return HYPHEN;
    }
    let currency = amount.currency;

    if (!currency) {
      currency = CurrencyEnum.PLN;
    }

    if (!format) {
      format = '1.2-2';
    }

    return this.currencyPipe.transform(amount.value, currency, 'symbol', format) ?? HYPHEN;
  }

  private isEmpty(amount: Amount): boolean {
    return !amount || amount.value === null || amount.value === undefined;
  }
}
