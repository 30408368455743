<div class="import-debts-last">
  <mat-card>
    <ng-container *ngIf="importFile">
      <p><strong>{{'DEBTS.IMPORT_FILE_NAME' | translate}}</strong>: {{importFile.filename}}</p>

      <p><strong>{{'DEBTS.IMPORT_FILE_ID' | translate}}</strong>: {{importFile.id}}</p>

      <div class="import-debts-last__wrapper">
        <p class="import-debts-last__status-wrapper">
          <span><strong>{{'DEBTS.IMPORT_FILE_STATUS' | translate}}</strong>:</span>
          <dc-import-debts-status [status]="importFile.status"></dc-import-debts-status>
        </p>

        <div class="import-debts-last__summary-wrapper">
          <p *ngIf="importFile.errorMessage"><strong>{{'DEBTS.IMPORT_FILE_ERROR' | translate}}</strong>: {{importFile.errorMessage}}</p>

          <p><strong>{{'DEBTS.IMPORT_FILE_SUMMARY' | translate}}</strong>:</p>
          <div>
            <p>{{ 'DEBTS.IMPORT_FILE_LIST_INSERTED' | translate}}: {{importFile.summary?.insertedRows}}</p>
            <p>{{ 'DEBTS.IMPORT_FILE_LIST_UPDATED' | translate}}: {{importFile.summary?.updatedRows}}</p>
            <p>{{ 'DEBTS.IMPORT_FILE_LIST_TOTAL' | translate}}: {{importFile.summary?.totalRows}}</p>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="!importFile">
      <p>{{'DEBTS.IMPORT_FILE_LIST_EMPTY' | translate}}</p>
    </ng-container>
  </mat-card>

</div>
