<div class="debtors" *ngIf="requestParams$ | async as requestParams">
  <div class="section-header">
    <h1>{{'DEBTORS.DEBTORS' | translate}}</h1>
  </div>

  <div class="dcol-table__container">
    <table
      class="dcol-table"
      aria-describedby="section-header"
      mat-table
      [dataSource]="(allDebtors$ | async) || []"
      multiTemplateDataRows
      matSort
      matSortActive="name"
      matSortDisableClear
      matSortDirection="asc"
      (matSortChange)="onSortChange($event, requestParams)"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DEBTORS.LIST.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DEBTORS.LIST.DATE_CREATED' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row.createdDate | date:'medium' }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTORS.LIST.EMAIL' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTORS.LIST.PHONE' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.phone}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="dcol-table__row" mat-row *matRowDef="let row; columns: displayedColumns;" [class.deletedRow]="row.deleted"></tr>

    </table>
  </div>

  <mat-paginator
    [length]="totalCount$ | async"
    [pageSize]="requestParams.limit"
    [pageIndex]="requestParams.page"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event, requestParams)"
  ></mat-paginator>

</div>
