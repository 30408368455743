import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ImportFileStatusEnum } from '@dc/data-models';

@Component({
  selector: 'dc-import-debts-status',
  templateUrl: './import-debts-status.component.html',
  styleUrls: ['./import-debts-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDebtsStatusComponent implements OnChanges {
  @Input() status!: ImportFileStatusEnum;

  public processList = [
    { status: ImportFileStatusEnum.TO_IMPORT, completed: false },
    { status: ImportFileStatusEnum.IN_PROGRESS, completed: false },
    { status: ImportFileStatusEnum.DONE, completed: false },
  ];

  constructor() {}

  public ngOnChanges(): void {
    let currentStepIndex = this.processList.findIndex((processItem) => processItem.status === this.status);

    this.processList.forEach((processItem, index) => {
      if (index <= currentStepIndex) {
        processItem.completed = true;
      }
    });
  }
}
