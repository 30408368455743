import {
  ComponentPortal,
  ComponentType,
  Portal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  public panel!: MatDrawer;
  private viewContainerRef!: ViewContainerRef;
  private panelPortal$ = new Subject<Portal<any> | null>();

  get panelPortal() {
    return from(this.panelPortal$);
  }

  constructor() {}

  setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  setPanelPortal(panelPortal: Portal<any>) {
    this.panelPortal$.next(panelPortal);
  }

  setPanelContent(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>
  ) {
    let portal: Portal<any>;
    if (componentOrTemplateRef instanceof TemplateRef) {
      const vcr = this.viewContainerRef ? this.viewContainerRef : null;
      portal = new TemplatePortal(
        componentOrTemplateRef,
        <ViewContainerRef>vcr
      );
    } else {
      portal = new ComponentPortal(componentOrTemplateRef);
    }
    this.panelPortal$.next(portal);
  }

  clearPanelPortal() {
    this.panelPortal$.next(null);
  }

  open(portal?: Portal<any>) {
    if (portal) {
      this.panelPortal$.next(portal);
    }
    return this.panel.open();
  }

  toggle() {
    return this.panel.toggle();
  }

  close() {
    return this.panel.close();
  }
}
