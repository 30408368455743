import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { HYPHEN } from '../helpers/utils';

@Pipe({
  name: 'pastDueDays',
})
export class PastDueDaysPipe implements PipeTransform {

  constructor() {}

  public static getDays(paymentDate: Date): number {
    const nowDT = DateTime.fromJSDate(new Date());
    const paymentDT = DateTime.fromJSDate(new Date(paymentDate));
    const result = nowDT.diff(paymentDT, ['days']);
    return -Math.floor(result.days);
  }

  public transform(dueDate: Date, format?: string): number | string {
    if (!dueDate) {
      return HYPHEN;
    }
    return PastDueDaysPipe.getDays(dueDate);

  }

}
