<div class="debts" *ngIf="requestParams$ | async as requestParams">
  <div class="section-header">
    <h1 class="section-header__title">{{'DEBTS.DEBTS' | translate}}</h1>

    <div class="section-header__topbar">
      <div>&nbsp;</div>
      <div class="debts__filters">
        <mat-form-field class="debts__list-select" appearance="outline">
          <mat-label>{{'DEBTS.LIST.COMPANY' | translate}}</mat-label>
          <mat-select [(value)]="companyId" (selectionChange)="onSelectedCompanyChange($event, requestParams)">
            <mat-option>---</mat-option>
            <mat-option *ngFor="let company of companies" [value]="company.id">
              {{ company.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="debts__list-select" appearance="outline">
          <mat-label>{{'DEBTS.LIST.DEBTOR' | translate}}</mat-label>
          <mat-select [(value)]="debtorId" (selectionChange)="onSelectedDebtorChange($event, requestParams)">
            <mat-option>---</mat-option>
            <mat-option *ngFor="let debtor of (allDebtors$ | async)" [value]="debtor.id">
              {{ debtor.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="dcol-table__container">
    <table
      class="dcol-table"
      aria-describedby="section-header__title"
      mat-table [dataSource]="(allDebts$ | async) || []"
      multiTemplateDataRows
      matSort
      matSortActive="paymentDate"
      matSortDisableClear
      matSortDirection="desc"
      (matSortChange)="onSortChange($event, requestParams)"
    >

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DEBTS.CREATED_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          {{element.createdDate | date:'medium'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="currentBalance">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.LIST.CURRENT_BALANCE' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.invoice.currentBalance | amount }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.LIST.INVOICE_NUMBER' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.invoice.number}}</td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.LIST.DEBTOR' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="invoicePaymentDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DEBTS.LIST.PAYMENT_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          {{element.invoice.paymentDate | date}}
          <strong>[{{ element.invoice.paymentDate | pastDueDays }} {{'DEBTS.LIST.DAYS' | translate}}]</strong> </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.LIST.ADDRESS' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <dc-address [address]="element.address" [showCountry]="false"></dc-address>
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="dcol-table__detail-container" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div class="debt-details">

              <div>
                <h2 class="debt-details__header">{{element.name}}</h2>
              </div>

              <div class="debt-details__container">

                <div class="debt-details__info">
                  <h3>{{'DEBTS.DEBT' | translate }}</h3>

                  <div>
                    <p class="debt-details__label">{{ 'DEBTS.LIST.INVOICE_NUMBER' | translate }}</p>
                    <p class="debt-details__detail debt-details__important-detail">{{element.invoice.number}}</p>
                    <p class="debt-details__label">{{ 'DEBTS.CREATED_DATE' | translate }}</p>
                    <p class="debt-details__detail ">{{ element.invoice.createdDate | date }}</p>
                    <p class="debt-details__label">{{ 'INVOICE_PAYMENT_DATE' | translate }}</p>
                    <p class="debt-details__detail debt-details__important-detail">{{element.invoice.paymentDate | date}}</p>
                  </div>

                  <div>
                    <p class="debt-details__label">{{ 'INVOICE_CURRENT_BALANCE' | translate }}</p>
                    <p class="debt-details__detail debt-details__important-detail">{{element.invoice.currentBalance | amount }}</p>
                    <p class="debt-details__label">{{ 'INVOICE_INITIAL_VALUE' | translate }}</p>
                    <p class="debt-details__detail">{{element.invoice.initialValue | amount}}</p>
                    <p class="debt-details__label">{{ 'INVOICE_PAID_VALUE' | translate }}</p>
                    <p class="debt-details__detail">
                      {{
                        { value: (element.invoice.initialValue.value - element.invoice.currentBalance.value), currency: element.invoice.currentBalance.currency } | amount
                      }}
                    </p>
                  </div>

                </div>

                <div>
                  <h4 class="debt-details__info-header">{{ 'DEBTS.CONTACT' | translate }}</h4>

                  <div>
                    <p class="debt-details__label">{{ 'DEBTS.EMAIL' | translate }}</p>
                    <p class="debt-details__detail">{{ element.email }}</p>
                    <p class="debt-details__label">{{ 'DEBTS.PHONE' | translate }}</p>
                    <p class="debt-details__detail">{{ element.phone }}</p>
                    <p class="debt-details__label">&nbsp;</p>
                    <p class="debt-details__detail">&nbsp;</p>
                  </div>
                </div>

                <div>
                  <h4 class="debt-details__info-header">{{ 'ADDRESS' | translate }}</h4>
                  <div>
                    <p class="debt-details__detail">
                      <dc-address [address]="element.address"></dc-address>
                    </p>
                    <p class="debt-details__detail">
                      <a href="https://maps.google.com/?q={{element.location.latitude}},{{element.location.longitude}}" target="_blank">
                        {{ 'DEBTS.LINK_TO_MAP' | translate }}
                        <mat-icon class="debt-details__detail-icon" [inline]="true">open_in_new</mat-icon>
                      </a>
                    </p>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                  </div>
                </div>

                <div>
                  <h4 class="debt-details__info-header">{{ 'OTHER' | translate }}</h4>
                </div>

              </div>
            </div>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
          class="dcol-table__row-clickable dcol-table__row-expandable"
          [class.dcol-table__detail-expanded]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="dcol-table__detail-row"></tr>

      <tr class="mat-row mat-no-data-row" *matNoDataRow>
        <td class="mat-cell dcol-table__no-data-row" colspan="9999">{{'DEBTS.LIST.EMPTY' | translate}}</td>
      </tr>

    </table>
  </div>

  <mat-paginator
    [length]="totalCount$ | async"
    [pageSize]="requestParams.limit"
    [pageIndex]="requestParams.page"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event, requestParams)"
  ></mat-paginator>

</div>
