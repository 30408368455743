import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { clone, DialogService } from '@dc/shared';
import { TranslateService } from '@ngx-translate/core';
import { Company, PAGE_SIZE_OPTIONS } from '@dc/data-models';
import { DebtsEntity, DebtsFacade, DebtsRequestParams } from '@dc/debts';
import { PageEvent } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSelectChange } from '@angular/material/select';
import { CompaniesService } from '../../../companies/services/companies.service';
import { Sort } from '@angular/material/sort';
import { DebtorsFacade } from '@dc/debtors';

@Component({
  selector: 'dc-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebtsComponent implements OnInit {
  public displayedColumns: string[] = [
    'createdDate',
    'currentBalance',
    'invoicePaymentDate',
    'invoiceNumber',
    'fullName',
    'address',
    'expand',
  ];
  public totalCount$ = this.debtsFacade.totalCount$;
  public allDebts$ = this.debtsFacade.allDebts$;
  public requestParams$ = this.debtsFacade.requestParams$;

  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public expandedElement!: DebtsEntity | null;

  public companies: Company[] = [];
  public companyId!: string;

  public allDebtors$ = this.debtorsFacade.allDebtors$;
  public debtorId!: string;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private companiesService: CompaniesService,
    private debtsFacade: DebtsFacade,
    private debtorsFacade: DebtorsFacade
  ) {}

  public ngOnInit(): void {
    this.debtsFacade.init();
    this.debtorsFacade.init();
    this.companiesService.getList().subscribe((companiesResponse) => {
      this.companies = companiesResponse.companies;
    });
  }

  public onPageChange(event: PageEvent, requestParams: DebtsRequestParams): void {
    this.debtsFacade.loadDebts({
      ...requestParams,
      page: event.pageIndex,
      limit: event.pageSize,
    });
  }

  public onSelectedCompanyChange(event: MatSelectChange, requestParams: DebtsRequestParams): void {
    this.debtsFacade.loadDebts({ ...requestParams, page: 0, companyId: event.value });
  }

  public onSelectedDebtorChange(event: MatSelectChange, requestParams: DebtsRequestParams): void {
    this.debtsFacade.loadDebts({ ...requestParams, page: 0, debtorId: event.value });
  }

  public onSortChange(event: Sort, requestParams: DebtsRequestParams): void {
    this.debtsFacade.loadDebts({
      ...requestParams,
      sort: event.active,
      order: event.direction,
    });
  }
}
