import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, UrlTree } from '@angular/router';
import { DateTime } from 'luxon';
import {
  ActivateInput,
  Administrator,
  AUTH_MODULE_CONFIG,
  Authenticate,
  AuthModuleConfig,
  COOKIE_CONFIG,
  RegisterInput,
  Session,
  SessionResponse,
} from '@dc/data-models';
import { ToastService } from '@dc/shared';
import { AuthFacade } from '../../+state/auth.facade';
import {
  AdministratorProfileResponse,
  CompanyInfoResponse,
  InactiveAccountResponse,
  Profile,
  ProfileResponse,
} from '../../+state/auth.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly path = {
    api: {
      session: '/sessions',
      sessionActivate: '/sessions/activate',
      account: '/account',
      accountCeidg: '/account/ceidg',
      accountRegister: '/account/register',
      profile: '/profile',
    },
    frontend: {
      auth: '/auth/login',
    },
  };

  private apiUrl: string = this.authModuleConfig.apiUrl;

  constructor(
    @Inject(AUTH_MODULE_CONFIG) public authModuleConfig: AuthModuleConfig,
    private http: HttpClient,
    private cookieService: CookieService,

    private authFacade: AuthFacade,
    private router: Router,
    private toastService: ToastService
  ) {}

  public login(authenticate: Authenticate): Observable<SessionResponse> {
    return this.http.post<SessionResponse>(this.apiUrl + this.path.api.session, authenticate).pipe(
      tap((res: SessionResponse) => {
        this.handleAuthentication(res.session);
      }),
      tap(() => {
        this.toastService.clearAll();
        this.toastService.success('MESSAGES.SUCCESS.YOU_ARE_LOGGED_IN');
      })
    );
  }

  public register(registerInput: RegisterInput): Observable<void> {
    return this.http.post<void>(this.apiUrl + this.path.api.account, registerInput);
  }

  public getInactiveAccount(token: string): Observable<InactiveAccountResponse> {
    return this.http.get<InactiveAccountResponse>(this.apiUrl + this.path.api.sessionActivate, { params: { token } });
  }

  public getCompanyRegistrationInfo(token: string, taxId: string): Observable<CompanyInfoResponse> {
    return this.http.get<CompanyInfoResponse>(this.apiUrl + this.path.api.accountCeidg, { params: { taxId, token } });
  }

  public activate(activateInput: ActivateInput): Observable<void> {
    return this.http.post<void>(this.apiUrl + this.path.api.sessionActivate, activateInput);
  }

  public logout(state?: string | null, redirectUrl?: string): void {
    this.clearServerSession().subscribe(() => {
      this.toastService.success('MESSAGES.SUCCESS.YOU_ARE_LOGGED_OUT');

      if (redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.router.createUrlTree([this.path.frontend.auth]);
        this.redirectToLogin(state);
      }
    });
  }

  public forceLogout(state?: string): void {
    this.clearSession();
    this.redirectToLogin(state);
  }

  public redirectToLogin(state?: string | null): Promise<boolean> {
    if (state) {
      return this.router.navigate([this.path.frontend.auth], { queryParams: { state } });
    } else {
      return this.router.navigate([this.path.frontend.auth]);
    }
  }

  public getLoginUrlTree(state?: string): UrlTree {
    if (state) {
      return this.router.createUrlTree([this.path.frontend.auth], { queryParams: { state } });
    } else {
      return this.router.createUrlTree([this.path.frontend.auth]);
    }
  }

  public handleAuthentication(session: Session): void {
    this.setCookie(session, COOKIE_CONFIG.authCookieName);
  }

  public checkCookieToken(cookieName: string): boolean {
    if (this.cookieService.check(cookieName)) {
      const cookie: Session = JSON.parse(this.cookieService.get(cookieName));
      return !!cookie?.id && !!cookie?.userId;
    }
    return false;
  }

  public getUser(): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>(this.apiUrl + this.path.api.profile).pipe(
      map((response: ProfileResponse) => {
        // important note, when calling this endpoint in Admin app the model returned to us is AdministratorProfileResponse,
        // so we are mapping it to ProfileResponse, so we can reuse Store and some components
        if (
          (<AdministratorProfileResponse>(<unknown>response)).profile.username &&
          (<AdministratorProfileResponse>(<unknown>response)).profile.fullName
        ) {
          return <ProfileResponse>{
            profile: this.mapAdminProfileToProfile((<AdministratorProfileResponse>(<unknown>response)).profile),
          };
        } else {
          return response;
        }
      })
    );
  }

  private mapAdminProfileToProfile(adminProfile: Administrator): Profile {
    const profile: Profile = {
      id: adminProfile.id,
      name: adminProfile.fullName,
      surname: '',
      email: adminProfile.username,
      phone: null,
      profile: null,
      bankDetails: null,
      personNumberId: null,
      createdDate: adminProfile.createdDate,
      updatedDate: adminProfile.lastUpdatedDate,
    };
    return profile;
  }

  private setCookie(session: Session, cookieName: string) {
    const now = DateTime.now();
    const expirationDate = now.plus({ minutes: COOKIE_CONFIG.authCookieExpirationTimeInMinutes }).toJSDate();

    this.cookieService.set(cookieName, JSON.stringify(session), {
      expires: expirationDate,
      secure: true,
      sameSite: 'None',
      path: '/',
    });
  }

  private clearServerSession(): Observable<void> {
    return this.http.delete<void>(this.authModuleConfig.apiUrl + this.path.api.session).pipe(
      tap(() => this.authFacade.logout()),
      tap(() => this.cookieService.delete(COOKIE_CONFIG.authCookieName))
    );
  }

  private clearSession(): void {
    this.authFacade.logout();
    this.cookieService.delete(COOKIE_CONFIG.authCookieName);
  }
}
