import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, Environment } from '@dc/data-models';
import { map, Observable } from 'rxjs';
import { clone } from '@dc/shared';
import {
  DebtorsRequestParams,
  DebtorsResponse,
  DEFAULT_DEBTORS_REQUEST_PARAMS,
} from '../+state/debtors/debtors.models';

@Injectable({
  providedIn: 'root',
})
export class DebtorsService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {}

  public getList(params: DebtorsRequestParams = clone(DEFAULT_DEBTORS_REQUEST_PARAMS)): Observable<DebtorsResponse> {
    return this.http.get<DebtorsResponse>(this.environment.apiUrl + '/debtors', { params }).pipe(
      map((debtorsResponse) => ({
        ...debtorsResponse,
        debtors: debtorsResponse.debtors.map((d) => ({ ...d, createdDate: d.createdDates, id: d._id })),
      }))
    );
  }
}
