import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User, UserResponse, UserTypeEnum, UserTypeIcon } from '@dc/data-models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PanelService } from '@dc/shared';
import { map, Observable } from 'rxjs';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'dc-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserComponent implements OnInit {
  @Input() user!: User;
  @Input() userId!: string;

  @Output() userAdded: EventEmitter<User> = new EventEmitter();

  public addUserForm!: FormGroup;

  public userNameCtrl = new FormControl('', [Validators.required, Validators.email]);
  public nameCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  public surnameCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  public userTypeCtrl = new FormControl('', [Validators.required]);
  public phoneCtrl = new FormControl('', [Validators.required, Validators.minLength(5)]);

  public userTypeEnum = UserTypeEnum;
  public readonly userTypeIcon = UserTypeIcon;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private panelService: PanelService
  ) {}

  public ngOnInit(): void {
    this.createUserForm();
    if (this.userId) {
      this.getUserData().subscribe();
    }
    if (this.user) {
      this.updateAdminFormData();
    }
  }

  public submit(): void {
    if (!this.addUserForm.valid) {
      return;
    }

    const userData: User = <User>{
      username: this.addUserForm.getRawValue().userName,
      name: this.addUserForm.getRawValue().name,
      surname: this.addUserForm.getRawValue().surname,
      phone: this.addUserForm.getRawValue().phone,
      userType: this.addUserForm.getRawValue().userType,
    };
    if (this.user) {
      this.usersService.update(this.user.id, userData).subscribe();
    } else {
      this.createUser(userData);
    }
  }

  private createUserForm(): void {
    this.addUserForm = this.formBuilder.group({
      name: this.nameCtrl,
      userName: this.userNameCtrl,
      surname: this.surnameCtrl,
      phone: this.phoneCtrl,
      userType: this.userTypeCtrl,
    });
  }

  private getUserData(): Observable<User> {
    return this.usersService.get(this.userId).pipe(
      map((resp: UserResponse) => {
        this.user = resp.user;
        this.updateAdminFormData();
        return this.user;
      })
    );
  }

  private updateAdminFormData(): void {
    this.nameCtrl.setValue(<string>this.user.name);
    this.surnameCtrl.setValue(this.user.surname);
    this.phoneCtrl.setValue(<string>this.user.phone);
    this.userTypeCtrl.setValue(<string>this.user.userType[0]);
    this.userNameCtrl.setValue(this.user.username);
  }

  private createUser(userData: User): void {
    this.usersService.create(userData).subscribe(() => {
      this.panelService.close();
      this.userAdded.emit(userData);
    });
  }
}
