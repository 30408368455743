<div class="side-panel">
  <h1 class="side-panel__header">{{'COMPANIES.ADD.HEADER' | translate}}</h1>
  <form name="addCompanyForm" [formGroup]="addCompanyForm" (ngSubmit)="submit()" autocomplete="off" class="side-panel__content">

    <div class="add-company__form-triple-column">
      <mat-form-field color="primary" class="add-company__name">
        <mat-label>{{'COMPANIES.ADD.NAME_FILED' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
        />
        <mat-error *ngIf="addCompanyForm.controls['name'].invalid">
          {{ 'VALIDATION.MIN_CHARACTERS' | translate:{amount:3} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary" class="add-company__tax-id">
        <mat-label>{{'COMPANIES.ADD.TAX_ID_FILED' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.TAX_ID_PLACEHOLDER' | translate}}"
          formControlName="taxationId"
          mask="000-000-00-00"
        />
        <mat-error *ngIf="addCompanyForm.controls['taxationId'].invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field >

      <mat-slide-toggle formControlName="isTaxPayer" labelPosition="before" class="add-company__tax-payer">
        {{'COMPANIES.ADD.VAT_PAYER' | translate}}
      </mat-slide-toggle>
    </div>

    <div class="side-panel__form-double-column">
      <mat-form-field color="primary" formGroupName="companyForm" >
        <mat-label>{{'COMPANIES.ADD.COMPANY_FORM_FILED' | translate}}</mat-label>
        <mat-select formControlName="formType"
                    placeholder="{{'COMPANIES.ADD.COMPANY_FORM_PLACEHOLDER' | translate}}" required>
          <mat-option>-</mat-option>
          <mat-option *ngFor="let companyForm of companyForms" [value]="companyForm">
            {{'COMPANIES.COMPANY_FORMS.'+companyForm | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.COMPANY_TYPE_FILED' | translate}}</mat-label>
        <mat-select formControlName="companyType"
                    placeholder="{{'COMPANIES.ADD.COMPANY_TYPE_PLACEHOLDER' | translate}}" required multiple>
          <mat-option *ngFor="let companyType of companyType" [value]="companyType">
            {{'COMPANIES.COMPANY_TYPES.'+companyType | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="side-panel__form-single-column">
      <mat-form-field appearance="outline">
        <mat-label>{{'COMPANIES.ADD.PCA_FORM_FILED' | translate}}</mat-label>
        <mat-chip-list #pcaList aria-label="Wybór PKD">
          <mat-chip
            *ngFor="let pca of pcas"
            (removed)="removePca(pca)">
            {{pca.code}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            placeholder="{{'COMPANIES.ADD.PCA_FORM_PLACEHOLDER' | translate}}"
            #pcaInput
            formControlName="pcaList"
            [matAutocomplete]="autoPca"
            [matChipInputFor]="pcaList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addPca($event)">
        </mat-chip-list>
        <mat-autocomplete #autoPca="matAutocomplete" (optionSelected)="selectedPca($event)">
          <mat-option *ngFor="let pca of filteredPca | async" [value]="pca">
            {{pca.code}} | {{pca.desc}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="side-panel__form-double-column" formGroupName="companyForm" *ngIf="showNcr">

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.NCR_NUMBER_FORM_FILED' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.NCR_NUMBER_FORM_PLACEHOLDER' | translate}}"
          formControlName="ncrNumber"
        />
      </mat-form-field>

      <mat-form-field  appearance="outline">
        <mat-label>{{'COMPANIES.ADD.NCR_FORM_FILED' | translate}}</mat-label>
        <input type="text"
               placeholder="{{'COMPANIES.ADD.NCR_FORM_PLACEHOLDER' | translate}}"
               aria-label="NCR"
               matInput
               formControlName="ncr"
               [matAutocomplete]="autoNCR">
        <mat-autocomplete autoActiveFirstOption #autoNCR="matAutocomplete" [displayWith]="ncrDispaly">
          <mat-option *ngFor="let ncr of filteredNcr | async" [value]="ncr" class="ncr-option">
            <span class="ncr-option__court">{{ncr.court}}</span>
            <span class="ncr-option__department">{{ncr.departments}}</span>
            <span class="ncr-option__address">{{ncr.address}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.CAPITAL_FORM_FILED' | translate}}</mat-label>
        <input
          matInput
          type="number"
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.CAPITAL_FORM_PLACEHOLDER' | translate}}"
          formControlName="capital"
        />
      </mat-form-field>

      <mat-slide-toggle formControlName="fullyPaidCapital" labelPosition="before" class="add-company__capital-fully-paid">
        {{'COMPANIES.ADD.CAPITAL_FULLY_PAID_FORM_FILED' | translate}}
      </mat-slide-toggle>

    </div>


    <p>{{'COMPANIES.ADD.ADDRESS.ADDRESS' | translate}}</p>

    <div formGroupName="address">
      <div class="add-company__form-triple-column">

        <mat-form-field color="primary" class="add-company__name">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.STREET_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.STREET_FROM_PLACEHOLDER' | translate}}"
            formControlName="street"
          />
          <mat-error *ngIf="addCompanyForm.controls['address'].get('street')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="primary" class="add-company__tax-id">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.BUILDING_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.BUILDING_FROM_PLACEHOLDER' | translate}}"
            formControlName="building"
          />
          <mat-error *ngIf="addCompanyForm.controls['address'].get('building')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="primary" class="add-company__tax-id">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.APARTMENT_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.APARTMENT_FROM_PLACEHOLDER' | translate}}"
            formControlName="apartment"
          />
          <mat-error *ngIf="addCompanyForm.controls['address'].get('apartment')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

      </div>

      <div class="side-panel__form-double-column" >

        <mat-form-field color="primary">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.POSTAL_CODE_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.POSTAL_CODE_FROM_PLACEHOLDER' | translate}}"
            formControlName="postalCode"
            mask="00-000"
          />
          <mat-hint>Format: 00-000 | Wpisuj tylko liczby</mat-hint>
          <mat-error *ngIf="addCompanyForm.controls['address'].get('postalCode')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="primary">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.CITY_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.CITY_FROM_PLACEHOLDER' | translate}}"
            formControlName="city"
          />
          <mat-error *ngIf="addCompanyForm.controls['address'].get('city')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="primary">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.VOIVODESHIP_FROM_FIELD' | translate}}</mat-label>
          <mat-select formControlName="voivodeship"
                      placeholder="{{'COMPANIES.ADD.ADDRESS.VOIVODESHIP_FROM_PLACEHOLDER' | translate}}" required>
            <mat-option>-</mat-option>
            <mat-option *ngFor="let voivodeship of voivodeships" [value]="voivodeship">
              {{voivodeship}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
          <mat-label>{{'COMPANIES.ADD.ADDRESS.COUNTRY_FROM_FIELD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="{{'COMPANIES.ADD.ADDRESS.COUNTRY_FROM_PLACEHOLDER' | translate}}"
            formControlName="country"
          />
          <mat-error *ngIf="addCompanyForm.controls['address'].get('country')?.invalid">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

      </div>
    </div>

    <p>{{'COMPANIES.ADD.CONTACT' | translate}}</p>

    <div class="side-panel__form-double-column" >

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.EMAIL_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.EMAIL_FROM_PLACEHOLDER' | translate}}"
          formControlName="email"
        />
        <mat-error *ngIf="addCompanyForm.controls['email'].invalid">
          {{ 'VALIDATION.INVALID_EMAIL' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.PHONE_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.PHONE_FROM_PLACEHOLDER' | translate}}"
          formControlName="phone"
        />
        <mat-error *ngIf="addCompanyForm.controls['phone'].invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>


    <p>{{'COMPANIES.ADD.ACCOUNT.ACCOUNT' | translate}}</p>

    <div class="side-panel__form-double-column" formGroupName="bankAccount">

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.ACCOUNT.BANK_NAME_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.ACCOUNT.BANK_NAME_FROM_PLACEHOLDER' | translate}}"
          formControlName="bankName"
        />
        <mat-error *ngIf="addCompanyForm.controls['bankAccount'].get('bankName')?.invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.ACCOUNT.SWIFT_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.ACCOUNT.SWIFT_FROM_PLACEHOLDER' | translate}}"
          formControlName="swift"
        />
        <mat-error *ngIf="addCompanyForm.controls['bankAccount'].get('swift')?.invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.ACCOUNT.COUNTRY_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.ACCOUNT.COUNTRY_FROM_PLACEHOLDER' | translate}}"
          formControlName="country"
        />
        <mat-error *ngIf="addCompanyForm.controls['bankAccount'].get('country')?.invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'COMPANIES.ADD.ACCOUNT.IBAN_FROM_FIELD' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'COMPANIES.ADD.ACCOUNT.IBAN_FROM_PLACEHOLDER' | translate}}"
          formControlName="iban"
          mask="AA 00 0000 0000 0000 0000 0000 0000"
        />
        <mat-hint>Format: PL 00 000 ...</mat-hint>
        <mat-error *ngIf="addCompanyForm.controls['bankAccount'].get('iban')?.invalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>


    </div>

    <div class="side-panel__form-single-column side-panel__actions">
      <button mat-raised-button color="accent" type="submit"> {{'COMPANIES.ADD.ADD_COMPANY' | translate}}</button>
    </div>



  </form>

</div>
