<div class="add-panel">
  <h3 *ngIf="!administrator">{{'ADMINISTRATORS.ADD_NEW' | translate}}</h3>
  <h3 *ngIf="administrator">{{'ADMINISTRATORS.EDIT' | translate}}</h3>

  <form name="addAdministratorForm" [formGroup]="addAdministratorForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="add-panel__form-single-column">

      <mat-form-field color="primary">
        <mat-label>{{'ADMINISTRATORS.FULL_NAME' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'ADMINISTRATORS.FULL_NAME_PLACEHOLDER' | translate}}"
          formControlName="fullName"
        />
        <mat-error *ngIf="addAdministratorForm.controls['fullName'].invalid">
          {{'VALIDATION.MIN_3_CHARS' | translate}}
        </mat-error>
      </mat-form-field>

    </div>
    <div class="add-panel__form-double-column">

      <mat-form-field color="primary">
        <mat-label>{{'ADMINISTRATORS.NAME' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'ADMINISTRATORS.NAME_PLACEHOLDER' | translate}}"
          formControlName="username"
        />
        <mat-error *ngIf="addAdministratorForm.controls['username'].invalid">
          {{'VALIDATION.MIN_3_CHARS' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'ADMINISTRATORS.EMAIL' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          type="email"
          placeholder="{{'ADMINISTRATORS.EMAIL_PLACEHOLDER' | translate}}"
          formControlName="email"
        />
        <mat-error *ngIf="addAdministratorForm.controls['email'].invalid">
          {{'VALIDATION.INVALID_EMAIL' | translate}}
        </mat-error>
      </mat-form-field>

      <div class="add-panel__form-action-row">

        <button
          mat-flat-button
          color="accent"
          class="submit-button"
          type="submit"
          *ngIf="!administrator"
        >
          <span class="submit-button-inner">
           {{'ADMINISTRATORS.ADD' | translate}}
          </span>
        </button>

        <button
          mat-flat-button
          color="accent"
          class="submit-button"
          type="submit"
          *ngIf="administrator"
        >
          <span class="submit-button-inner">
           {{'ADMINISTRATORS.SAVE' | translate}}
          </span>
        </button>

      </div>

    </div>
  </form>
</div>
