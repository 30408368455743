import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATA_DEBTORS_FEATURE_KEY, DebtorsState, debtorsAdapter } from './debtors.reducer';

// Lookup the 'Debtors' feature state managed by NgRx
export const getDebtorsState = createFeatureSelector<DebtorsState>(DATA_DEBTORS_FEATURE_KEY);

const { selectAll, selectEntities } = debtorsAdapter.getSelectors();

export const getDebtorsLoaded = createSelector(getDebtorsState, (state: DebtorsState) => state.loaded);

export const getDebtorsError = createSelector(getDebtorsState, (state: DebtorsState) => state.error);

export const getAllDebtors = createSelector(getDebtorsState, (state: DebtorsState) => selectAll(state));

export const getRequestParams = createSelector(getDebtorsState, (state: DebtorsState) => state.requestParams);

export const getTotalCount = createSelector(getDebtorsState, (state: DebtorsState) => state.totalCount);

export const getDebtorsEntities = createSelector(getDebtorsState, (state: DebtorsState) => selectEntities(state));

export const getSelectedId = createSelector(getDebtorsState, (state: DebtorsState) => state.selectedId);

export const getSelected = createSelector(getDebtorsEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);
