<div class="import-debts">

  <div class="import-debts__table-container dcol-table__container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDisableClear matSortDirection="desc">

      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.IMPORT_FILE_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.filename}}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{'DEBTS.IMPORT_FILE_ID' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{'DEBTS.IMPORT_FILE_STATUS' | translate}}
        </th>
        <td mat-cell *matCellDef="let row" class="import-debts__status">
          <dc-import-debts-status [status]="row.status"></dc-import-debts-status>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorMessage">
        <th mat-header-cell *matHeaderCellDef>
          {{'DEBTS.IMPORT_FILE_ERROR' | translate}}
        </th>
        <td mat-cell *matCellDef="let row">{{row.errorMessage}}</td>
      </ng-container>

      <ng-container matColumnDef="summary">
        <th mat-header-cell *matHeaderCellDef>
          {{'DEBTS.IMPORT_FILE_SUMMARY' | translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="dcol-table__cell_multiline" *ngIf="row.summary">
            <span class="dcol-table__cell-multiline-item">{{ 'DEBTS.IMPORT_FILE_LIST_INSERTED' | translate}}: {{row.summary?.insertedRows}}</span>
            <span class="dcol-table__cell-multiline-item">{{ 'DEBTS.IMPORT_FILE_LIST_UPDATED' | translate}}: {{row.summary?.updatedRows}}</span>
            <span class="dcol-table__cell-multiline-item">{{ 'DEBTS.IMPORT_FILE_LIST_TOTAL' | translate}}: {{row.summary?.totalRows}}</span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.deletedRow]="row.deleted"></tr>
      <tr class="mat-row mat-no-data-row" *matNoDataRow>
        <td class="mat-cell dcol-table__no-data-row" colspan="9999">{{'DEBTS.IMPORT_FILE_LIST_EMPTY' | translate}}</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="requestParams.limit"
    [pageIndex]="requestParams.page"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChange($event)"
  ></mat-paginator>

</div>
