import { createAction, props } from '@ngrx/store';
import { DebtsEntity, DebtsRequestParams } from './debts.models';

export const initDebts = createAction('[Debts Page] Init');

export const initDebtsPublic = createAction('[Debts Page] Init Public');

export const loadDebts = createAction('[Debts Page] Load Debts', props<{ requestParams: DebtsRequestParams }>());
export const loadDebtsSuccess = createAction(
  '[Debts/API] Load Debts Success',
  props<{ debts: DebtsEntity[]; totalCount: number }>()
);
export const loadDebtsFailure = createAction('[Debts/API] Load Debts Failure', props<{ error: any }>());

export const loadDebt = createAction('[Debts Page] Load Debt', props<{ debtId: string }>());
export const loadDebtSuccess = createAction('[Debts/API] Load Debt Success', props<{ debt: DebtsEntity }>());
export const loadDebtFailure = createAction('[Debts/API] Load Debt Failure', props<{ error: any }>());

export const selectDebt = createAction('[Debts Page] Select Debt', props<{ debtId: string }>());

export const loadDebtsPublic = createAction(
  '[Debts Page] Load Debts Public',
  props<{ requestParams: DebtsRequestParams }>()
);
export const loadDebtsPublicSuccess = createAction(
  '[Debts/API] Load Debts Public Success',
  props<{ debts: DebtsEntity[]; totalCount: number }>()
);
export const loadDebtsPublicFailure = createAction('[Debts/API] Load Debts Public Failure', props<{ error: any }>());

export const assignDebt = createAction('[Debts Map] Assign debt', props<{ debtId: string }>());
export const assignDebtSuccess = createAction('[Debts Map] Assign debt success', props<{ debtId: string }>());
export const assignDebtFailure = createAction('[Debts Map] Assign debt failure', props<{ error: any }>());
