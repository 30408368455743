<div class="add-panel">
  <h3 *ngIf="!user">{{'USERS.ADD_USER' | translate}}</h3>
  <h3 *ngIf="user">{{'USERS.EDIT_USER' | translate}}</h3>

  <form name="addUserForm" [formGroup]="addUserForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="add-panel__form-single-column">

      <mat-form-field color="primary">
        <mat-label>{{'USERS.USERNAME' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'USERS.USERNAME_PLACEHOLDER' | translate}}"
          formControlName="userName"
        />
        <mat-error *ngIf="userNameCtrl.invalid">
          {{'VALIDATION.INVALID_EMAIL' | translate}}
        </mat-error>
      </mat-form-field>

    </div>
    <div class="add-panel__form-double-column">

      <mat-form-field color="primary">
        <mat-label>{{'USERS.NAME' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'USERS.NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
        />
        <mat-error *ngIf="nameCtrl.invalid">
          {{'VALIDATION.MIN_3_CHARS' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'USERS.SURNAME' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'USERS.SURNAME_PLACEHOLDER' | translate}}"
          formControlName="surname"
        />
        <mat-error *ngIf="surnameCtrl.invalid">
          {{'VALIDATION.MIN_3_CHARS' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'USERS.PHONE' | translate}}</mat-label>
        <input
          matInput
          autocomplete="off"
          placeholder="{{'USERS.PHONE_PLACEHOLDER' | translate}}"
          formControlName="phone"
        />
        <mat-error *ngIf="phoneCtrl.invalid">
          {{'VALIDATION.MIN_5_CHARS' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="primary">
        <mat-label>{{'USERS.USER_TYPE' | translate}}</mat-label>
        <mat-select formControlName="userType"
                    placeholder="{{'USERS.USER_TYPE_PLACEHOLDER' | translate}}">
          <mat-select-trigger>
            {{ 'USERS.USER_TYPES.' + userTypeCtrl.value | translate }}
          </mat-select-trigger>
          <mat-option>-</mat-option>
          <mat-option [value]="userTypeEnum.COLLECTOR">
            <mat-icon fontSet="material-icons-outlined">
              {{ userTypeIcon[userTypeEnum.COLLECTOR] }}
            </mat-icon>
            {{ 'USERS.USER_TYPES.' + userTypeEnum.COLLECTOR | translate }}
          </mat-option>
          <mat-option [value]="userTypeEnum.CREDITOR">
            <mat-icon fontSet="material-icons-outlined">
              {{ userTypeIcon[userTypeEnum.CREDITOR] }}
            </mat-icon>
            {{ 'USERS.USER_TYPES.' + userTypeEnum.CREDITOR | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="add-panel__form-action-row">

      <button
        mat-flat-button
        color="accent"
        class="submit-button"
        type="submit"
        *ngIf="!user"
      >
        <span class="submit-button-inner">
         {{'USERS.ADD_USER' | translate}}
        </span>
      </button>

      <button
        mat-flat-button
        color="accent"
        class="submit-button"
        type="submit"
        *ngIf="user"
      >
        <span class="submit-button-inner">
         {{'USERS.SAVE' | translate}}
        </span>
      </button>

    </div>

  </form>
</div>
