<div class="companies">
  <div class="section-header">
    <h1>{{'COMPANIES.COMPANIES' | translate}}</h1>

    <div class="section-header__topbar">
      <button class="projects-add-btn" mat-flat-button color="accent" type="button" (click)="openSidePanel(addCompanyTemplateRef)">
        <span class="material-icons">add</span>
        {{'COMPANIES.ADD_COMPANY' | translate}}
      </button>

      <div>
        <mat-slide-toggle labelPosition="before" (change)="deletedFilterChanged($event)">
          {{'COMPANIES.SHOW_DELETED' | translate}}
        </mat-slide-toggle>
      </div>

    </div>
  </div>

  <ng-template #addCompanyTemplateRef>
     <dc-add-company (companyAdded)="getListData()"></dc-add-company>
  </ng-template>

  <ng-template #editCompanyTemplateRef>
    <h2>Edytuj Firme</h2>
  </ng-template>

  <div class="companies__table-container">
    <table mat-table [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <!-- TaxId Column -->
      <ng-container matColumnDef="taxId">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.TAX_ID' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row?.taxationId || '-' | mask: '000-000-00-00'}}</td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.PHONE' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.phone | mask: '+00 000 000 000' }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.EMAIL' | translate}}</th>
        <td mat-cell *matCellDef="let row">
          <a href="mailto:{{row.email}}" target="_blank">{{row.email}}</a></td>
      </ng-container>

      <!-- Company Form Column -->
      <ng-container matColumnDef="companyForm">
        <th mat-header-cell *matHeaderCellDef>{{'COMPANIES.LIST.COMPANY_FORM' | translate}}</th>
        <td mat-cell *matCellDef="let row">
          {{ 'COMPANIES.COMPANY_FORMS.'+row.companyForm.formType | translate}}
        </td>
      </ng-container>


      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="dcol-table__action-column">
          <button *ngIf="!row.deleted" type="button" mat-icon-button (click)="remove(row, $event)" matTooltip="{{'DELETE' | translate}}" aria-label="Remove">
            <mat-icon class="dcol-icon-red" fontSet="material-icons-outlined">delete</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="openSidePanel(editCompanyTemplateRef, row, $event)" aria-label="Remove"
                  matTooltip="{{'EDIT' | translate}}">
            <mat-icon fontSet="material-icons-outlined" class="dcol-icon-orange">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="dcol-table__row-clickable" mat-row *matRowDef="let row; columns: displayedColumns;" [class.deletedRow]="row.deleted" (click)="elementClicked(row)"></tr>
    </table>
  </div>
</div>
