<div class="item-details" *ngIf="user">
  <h1 class="item-details__header">
    <button class="item-details__back-btn" mat-icon-button type="button" matTooltip="{{ 'MENU.BACK' | translate }}"
            [routerLink]="'..'">
      <mat-icon>arrow_back</mat-icon>
    </button>
    {{user.name}} {{user.surname}}
    &nbsp;
    <mat-icon class="item-details__type" fontSet="material-icons-outlined" matTooltip="{{ 'USERS.USER_TYPES.' + user.userType | translate }}">
      {{ userTypeIcon[user.userType[0]] }}
    </mat-icon>
  </h1>

  <div class="item-details__cards">
    <mat-card class="item-details__card">
      <mat-card-title>{{ 'USERS.CONTACT_DETAILS' | translate }}</mat-card-title>
      <mat-card-content>
        <p *ngIf="user.username"><mat-icon  matTooltip="{{ 'USERS.USERNAME' | translate }}" fontSet="material-icons-outlined">badge</mat-icon> {{user.username}}</p>
        <p *ngIf="user.email"><mat-icon  matTooltip="{{ 'USERS.EMAIL' | translate }}" fontSet="material-icons-outlined">email</mat-icon> {{user.email}}</p>
        <p *ngIf="user.phone"><mat-icon  matTooltip="{{ 'USERS.PHONE' | translate }}" fontSet="material-icons-outlined">phone</mat-icon> {{user.phone}}</p>
      </mat-card-content>
    </mat-card>
    <mat-card class="item-details__card">
      <mat-card-title>{{ 'USERS.USER_DETAILS' | translate }}</mat-card-title>
      <mat-card-content>
        <p>
          <mat-icon fontSet="material-icons-outlined" matTooltip="{{ 'USERS.USER_TYPES.' + user.userType | translate }}">
            {{ userTypeIcon[user.userType[0]] }}
          </mat-icon>
          {{ 'USERS.USER_TYPES.' + user.userType | translate }}
        </p>
        <p *ngIf="user.createdDate"><mat-icon matTooltip="{{ 'USERS.DATE_CREATED' | translate }}" fontSet="material-icons-outlined">today</mat-icon> {{user.createdDate | date }}</p>
        <p *ngIf="user.updatedDate"><mat-icon matTooltip="{{ 'USERS.DATE_UPDATED' | translate }}" fontSet="material-icons-outlined">edit_calendar</mat-icon> {{user.updatedDate | date}}</p>
        <p *ngIf="user.activatedDate"><mat-icon matTooltip="{{ 'USERS.DATE_ACTIVATED' | translate }}" fontSet="material-icons-outlined">event_available</mat-icon> {{user.activatedDate | date}}</p>
      </mat-card-content>
    </mat-card>
  </div>

</div>
