import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { APP_META, MenuItem, PanelService } from '@dc/shared';
import { combineLatest, map } from 'rxjs';
import { AuthFacade, Profile } from '@dc/auth';

@Component({
  selector: 'dc-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent implements OnInit {
  @ViewChild('drawer', { static: true }) private drawer!: MatDrawer;

  public version = APP_META.version;
  public sideBarOpen = true;

  public vm$ = combineLatest([this.authFacade.user$]).pipe(
    map(([profile]): { profile: Profile | null } => ({ profile }))
  );

  public menuItems: MenuItem[] = [
    {
      labelKey: 'MENU.DEBTS',
      icon: 'currency_exchange',
      routerLink: '/debts',
    },
    {
      labelKey: 'MENU.DEBTORS',
      icon: 'person_search',
      routerLink: '/debtors',
    },
    {
      labelKey: 'MENU.COMPANIES',
      icon: 'apartment',
      routerLink: '/companies',
    },
    {
      labelKey: 'MENU.USERS',
      icon: 'face',
      routerLink: '/users',
    },
    {
      labelKey: 'MENU.ADMINS',
      icon: 'people',
      routerLink: '/administrators',
    },
  ];

  constructor(public panelService: PanelService, private authFacade: AuthFacade) {}

  public ngOnInit(): void {
    this.panelService.panel = this.drawer;
  }

  public onSidebarToggled(): void {
    this.sideBarOpen = !this.sideBarOpen;
    this.panelService.close();
  }
}
