import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as CollectorMapActions from './collector-map.actions';
import { catchError, exhaustMap, map, Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { DebtsService } from '../../services/debts.service';

@Injectable()
export class CollectorMapEffects {
  init$ = createEffect(
    () => <Observable<Action>>this.actions$.pipe(
        ofType(CollectorMapActions.initCollectorMap),
        exhaustMap(() => {
          return this.debtsService.getList().pipe(
            map(() => {
              return of(CollectorMapActions.loadCollectorMapSuccess({ collectorMap: [] }));
            }),
            catchError((error) => {
              return of(CollectorMapActions.loadCollectorMapFailure({ error }));
            })
          );
        })
      )
  );

  constructor(private readonly actions$: Actions, private debtsService: DebtsService) {}
}
