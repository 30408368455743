import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CollectorDebtStatusEnum, DebtStatusEnum } from '@dc/debts';

@Component({
  selector: 'dc-debt-queue',
  templateUrl: './debt-queue.component.html',
  styleUrls: ['./debt-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebtQueueComponent {
  @Input() collectorQueueNumber!: number;
  @Input() queueLength!: number;
  @Input() collectorStatus!: DebtStatusEnum;

  public readonly debtsStatusEnum = DebtStatusEnum;
}
