import { ErrorHandler, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { MaterialModule } from '@dc/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Settings } from 'luxon';
import { ErrorService } from './services/error.service';
import { ToastService } from './services/toast.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './helpers/paginator.i18n';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import localePl from '@angular/common/locales/pl';
import { AmountPipe } from './pipes/amount.pipe';
import { AddressComponent } from './components/address/address.component';
import { RouterModule } from '@angular/router';
import { PastDueDaysPipe } from './pipes/pastDueDays.pipe';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from './components/toast/toast.component';
import { MenuComponent } from './components/menu/menu.component';
import { NgxMaskModule } from 'ngx-mask';

const DEFAULT_LOCALE = 'pl';

registerLocaleData(localePl);

export function HttpLoaderFactory(httpBackend: HttpBackend) {
  const http = new HttpClient(httpBackend);
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    GoogleMapsModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpBackend] },
      defaultLanguage: DEFAULT_LOCALE,
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    NgxMaskModule.forRoot(),
  ],
  declarations: [MenuComponent, ConfirmDialogComponent, AddressComponent, AmountPipe, PastDueDaysPipe, ToastComponent],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    GoogleMapsModule,
    TranslateModule,
    PortalModule,
    LayoutModule,
    NgxMaskModule,

    AddressComponent,
    MenuComponent,
    ToastComponent,

    AmountPipe,
    PastDueDaysPipe,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
        {
          // all Errors
          provide: ErrorHandler,
          useClass: ErrorService,
        },
        ToastService,
        CurrencyPipe,
        {
          provide: MatPaginatorIntl,
          deps: [TranslateService],
          useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl(),
        },
      ],
    };
  }
  constructor(public translationService: TranslateService, paginatorIntl: MatPaginatorIntl) {
    this.translationService.store.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.translationService.use(lang.lang);
      paginatorIntl.changes.next();
      Settings.defaultLocale = lang.lang;
    });
    Settings.defaultLocale = DEFAULT_LOCALE;
  }
}
