import { BankAccount } from '../common/shared.model';
import { ListResponseOK, ResponseOK } from '../common/response.model';
import { RequestParams } from '../common/lists';

export interface User {
  id: string;
  username: string;
  email?: string;
  name: string;
  surname: string;
  phone?: string | null;
  personId?: string | null;
  userType: UserTypeEnum[];
  creditorProfile: string | null;
  collectorProfile: string | null;
  bankAccount: BankAccount | null;
  createdDate: Date;
  updatedDate: Date;
  activatedDate: Date | null;
}

export interface UserResponse extends ResponseOK {
  user: User;
}

export interface UsersResponse extends ListResponseOK {
  users: User[];
}

export interface UsersRequestParams extends RequestParams {
  userType?: UserTypeEnum;
}

export enum UserTypeEnum {
  COLLECTOR = 'COLLECTOR',
  CREDITOR = 'CREDITOR',
}

export const UserTypeIcon: { [key: string]: string } = {
  [UserTypeEnum.COLLECTOR]: 'savings',
  [UserTypeEnum.CREDITOR]: 'psychology',
};
