import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ENVIRONMENT, Environment, RequestParams } from '@dc/data-models';
import { Observable } from 'rxjs';
import { clone, removeEmpty } from '@dc/shared';
import { DebtResponse, DebtsResponse, DEFAULT_DEBTS_REQUEST_PARAMS } from './../+state/debts/debts.models';
import { CollectorDebtsResponse, DEFAULT_COLLECTOR_DEBTS_REQUEST_PARAMS } from '@dc/debts';

@Injectable({
  providedIn: 'root',
})
export class DebtsService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {}

  public getList(params: RequestParams = clone(DEFAULT_DEBTS_REQUEST_PARAMS)): Observable<DebtsResponse> {
    return this.http.get<DebtsResponse>(this.environment.apiUrl + '/debts', {
      params: <HttpParams>removeEmpty(params),
    });
  }

  public getCollectorPublicList(
    params: RequestParams = clone(DEFAULT_DEBTS_REQUEST_PARAMS)
  ): Observable<DebtsResponse> {
    return this.http.get<DebtsResponse>(this.environment.apiPublicUrl + '/debts', {
      params: <HttpParams>removeEmpty(params),
    });
  }

  public getDebt(debtId: string): Observable<DebtResponse> {
    return this.http.get<DebtResponse>(this.environment.apiUrl + '/debts/' + debtId);
  }

  public getCollectorDebts(
    params: RequestParams = clone(DEFAULT_COLLECTOR_DEBTS_REQUEST_PARAMS)
  ): Observable<CollectorDebtsResponse> {
    return this.http.get<CollectorDebtsResponse>(this.environment.apiUrl + '/debts/assignments', {
      params: <HttpParams>removeEmpty(params),
    });
  }

  public assignDebt(debtId: string): Observable<void> {
    return this.http.post<void>(this.environment.apiUrl + '/debts/assignments', { debtId });
  }

  public removeAssignment(debtId: string): Observable<void> {
    return this.http.delete<void>(this.environment.apiUrl + '/debts/assignments/' + debtId);
  }

  public acceptAssignment(debtId: string): Observable<void> {
    return this.http.put<void>(this.environment.apiUrl + '/debts/assignments/' + debtId, {});
  }
}
