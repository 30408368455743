import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from './menu.model';

@Component({
  selector: 'dc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() menuItems!: MenuItem[];
}
