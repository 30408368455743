<div class="debt-queue">

  <div class="debt-queue__title">
    {{ 'DEBTS.COLLECTOR_QUEUE' | translate }}:
  </div>

  <div class="debt-queue__label-wrapper">
    <div class="debt-queue__label-number">{{collectorQueueNumber || '-'}}</div>
    <div class="debt-queue__label-sub" *ngIf="collectorQueueNumber && collectorStatus!==debtsStatusEnum.ACTIVE">
      {{ 'DEBTS.COLLECTOR_QUEUE_POSITION' | translate }}
    </div>
    <div class="debt-queue__label-sub" *ngIf="collectorStatus===debtsStatusEnum.ACTIVE">
      {{ 'DEBTS.STATUS.ACTIVE' | translate | lowercase }}
    </div>
    <div class="debt-queue__label-sub" *ngIf="!collectorQueueNumber">{{ 'DEBTS.QUEUE_EMPTY' | translate }}</div>
  </div>

  <div class="debt-queue__title">
    {{ 'DEBTS.DEBT_QUEUE' | translate }}:
  </div>
  <div class="debt-queue__label-wrapper">
    <div class="debt-queue__label-number">{{queueLength || '-'}}</div>
    <div class="debt-queue__label-sub" *ngIf="queueLength === 1">{{ 'DEBTS.QUEUE_LENGTH_ONE' | translate }}</div>
    <div class="debt-queue__label-sub" *ngIf="queueLength && queueLength > 1">{{ 'DEBTS.QUEUE_LENGTH_MORE' | translate }}</div>
    <div class="debt-queue__label-sub" *ngIf="!queueLength">{{ 'DEBTS.QUEUE_EMPTY' | translate }}</div>
  </div>

</div>
